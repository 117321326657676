<template>
  <section v-if="aggregatedData" :class="{ 'edit-mode': editMode }">
    <v-container>
      <v-row class="mb-10 mt-10 d-flex px-3 justify-space-between">
        <router-link to="/manage-export" class="text-decoration-none">
          <CtaButton>Indietro</CtaButton>
        </router-link>
        <LogoutButton />
      </v-row>

      <v-row>
        <v-col pb-10 class="title-container col-12">
          <h1 class="display-1 font-weight-bold text-uppercase">
            <v-icon class="list-icon">fas fa-list</v-icon> Rendicontazione
            comune trimestrale pdf
          </h1>
        </v-col>

        <v-col pt-5 pb-5 class="col-12">
          <h2 class="text-uppercase sub-title font-weight-bold">
            Riepilogo
          </h2>
          <h3 subtitle-1 class="text-subtitle-1">
            Controlla il riepilogo dei pernottamenti registrati nel trimestre
            selezionato. <br />
            Da questa pagina puoi:<br />
            <ul>
              <li>
                Salvare ed esportare il file "Rendicontazione comune
                trimestrale"
              </li>
              <li>
                Modificare i totali dei pernottamenti del trimestre selezionato,
                aggiungendo eventuali check-in registrati su supporti
                alternativi al Tourist Tax Pro
              </li>
              <li>Tornare in Tourist Tax Pro per inserire nuovi check-in</li>
            </ul>
          </h3>

          <hr class="my-8" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <div class="summary-item">
            <h4>Periodo d'imposta</h4>
            <p>
              {{ formatDate(aggregatedData.startDate) }} -
              {{ formatDate(aggregatedData.endDate) }}
            </p>
          </div>
          <div class="summary-item">
            <h4>Denominazione struttura</h4>
            <p>{{ aggregatedData.facility.name }}</p>
          </div>
          <div class="summary-item">
            <h4>Sede legale</h4>
            <p>{{ aggregatedData.facility.registered_office }}</p>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="summary-item">
            <h4>Imposta di soggiorno anno</h4>
            <p>{{ aggregatedData.year }}</p>
          </div>
          <div class="summary-item">
            <h4>Tipologia struttura</h4>
            <p>{{ aggregatedData.facility.type }}</p>
          </div>
          <div class="summary-item">
            <h4>Codice fiscale/p.iva</h4>
            <p>{{ aggregatedData.facility.vat_number }}</p>
          </div>
          <div class="summary-item">
            <h4>Soggetto responsabile della struttura</h4>
            <p>{{ aggregatedData.facility.company_name }}</p>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col pt-5 pb-5 class="col-12">
          <h2 class="text-uppercase sub-title font-weight-bold">
            Pernottamenti
          </h2>
          <h3 subtitle-1 class="text-subtitle-1">
            Controlla il riepilogo dei pernottamenti registrati nel trimestre
            selezionato.<br />
            Il riepilogo si riferisce a tutti i check-in registrati su Tourist
            Tax Pro nel periodo selezionato.<br /><br />

            Se hai registrato altri check-in a mano devi aggiungere i relativi
            pernottamenti al riepilogo che vedi in questa pagina, ti consigliamo
            di tornare in Tourist Tax Pro cliccando il pulsante "inserisci nuovo
            check-in". In questo modo potrai registrare i check-in mancanti e
            fare in modo che il totale dei pernottamenti e il relativo file di
            esportazione siano corretti. <br /><br />
            Se invece vuoi modificare solo i totali dei pernottamenti, clicca il
            pulsante modifica.
          </h3>

          <br /><br />
          <v-btn
            elevation="2"
            color="#ec2b3c"
            class="white--text send logout"
            @click="editMode = !editMode"
            >{{ editMode ? "Annulla" : "Modifica" }}</v-btn
          >
          <span class="inline-block mx-8">o</span>
          <v-btn
            elevation="2"
            color="#ec2b3c"
            class="white--text send logout"
            @click="$router.push('/insert')"
            >Inserisci nuovo checkin</v-btn
          >
        </v-col>
      </v-row>

      <v-row>
        <v-col pt-5 pb-5 class="col-12">
          <h3 class="section-title"><span>Totale pernottamenti</span></h3>
        </v-col>
        <v-col cols="12">
          <div class="exemption-item flex align-center">
            <input
              type="number"
              v-model="aggregatedData.totalStays"
              min="0"
              step="1"
              style="width: 141px; display: inline-block;"
              :disabled="!editMode"
            />

            <span>di cui:</span>
          </div>
        </v-col>
        <v-col
          cols="6"
          v-for="(count, exemption) in aggregatedData.exemptions"
          :key="exemption"
          class="exemption-item"
        >
          <v-row class="align-center">
            <v-col cols="9">
              <h4>{{ exemptions[exemption].label }}</h4>
              <p>{{ exemptions[exemption].description }}</p>
            </v-col>
            <v-col cols="3">
              <input
                type="number"
                v-model="aggregatedData.exemptions[exemption]"
                min="0"
                step="1"
                class="block"
                :disabled="!editMode"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="quarter-item">
        <v-col pt-5 pb-5 class="col-12">
          <h3 class="section-title red-text"><span>Airbnb</span></h3>
          <p>
            Riepilogo pernottamenti con imposta a carico del gestore del portale
            telematico
          </p>
        </v-col>
        <v-col cols="3">
          <h3 class="month-header">Mese</h3>

          <ul>
            <li class="month-item small">
              {{ getMonthLabel(0) }}
            </li>
            <li class="month-item small">
              {{ getMonthLabel(1) }}
            </li>
            <li class="month-item small">
              {{ getMonthLabel(2) }}
            </li>
          </ul>
        </v-col>
        <v-col cols="4">
          <h3 class="month-header">
            N° PERNOTT. VERSATI DAL GESTORE DEL PORTALE
          </h3>
          <ul>
            <li class="month-item">
              <input
                type="number"
                :value="aggregatedData.uncollectedTaxes[0].stays"
                @input="
                  setStays(
                    aggregatedData.uncollectedTaxes,
                    0,
                    $event.target.value
                  )
                "
                :disabled="!editMode"
              />
            </li>
            <li class="month-item">
              <input
                type="number"
                :value="aggregatedData.uncollectedTaxes[1].stays"
                @input="
                  setStays(
                    aggregatedData.uncollectedTaxes,
                    1,
                    $event.target.value
                  )
                "
                :disabled="!editMode"
              />
            </li>
            <li class="month-item">
              <input
                type="number"
                :value="aggregatedData.uncollectedTaxes[2].stays"
                @input="
                  setStays(
                    aggregatedData.uncollectedTaxes,
                    2,
                    $event.target.value
                  )
                "
                :disabled="!editMode"
              />
            </li>
          </ul>
        </v-col>
        <v-col cols="5" class="gray">
          <h3 class="month-header">
            IMPORTO PER MESE RISCOSSO DAL PORTALE TELEMATICO
          </h3>
          <ul>
            <li class="month-item">
              <span>€ </span>
              <input
                type="number"
                min="0"
                step="0.01"
                :value="aggregatedData.uncollectedTaxes[0].tax / 100"
                @input="
                  setPrice(
                    aggregatedData.uncollectedTaxes,
                    0,
                    $event.target.value
                  )
                "
                :disabled="!editMode"
              />
            </li>
            <li class="month-item">
              <span>€ </span>
              <input
                type="number"
                min="0"
                step="0.01"
                :value="aggregatedData.uncollectedTaxes[1].tax / 100"
                @input="
                  setPrice(
                    aggregatedData.uncollectedTaxes,
                    1,
                    $event.target.value
                  )
                "
                :disabled="!editMode"
              />
            </li>
            <li class="month-item">
              <span>€ </span>
              <input
                type="number"
                min="0"
                step="0.01"
                :value="aggregatedData.uncollectedTaxes[2].tax / 100"
                @input="
                  setPrice(
                    aggregatedData.uncollectedTaxes,
                    2,
                    $event.target.value
                  )
                "
                :disabled="!editMode"
              />
            </li>
          </ul>
        </v-col>
        <v-col cols="5">
          <h3 class="month-header">
            PERNOTTAMENTI CON IMPOSTA VERSATA DAL GESTORE DEL PORTALE TELEMATICO
          </h3>
          <ul>
            <li class="month-item">
              {{ aggregatedData.uncollectedTaxes.total.stays }}
            </li>
          </ul>
        </v-col>
        <v-col cols="2"></v-col>
        <v-col cols="5" class="gray">
          <h3 class="month-header">
            TRIBUTO RISCOSSO DAL GESTORE DEL PORTALE TELEMATICO
          </h3>
          <ul>
            <li class="month-item">
              {{
                getPrice(
                  aggregatedData.uncollectedTaxes[0].tax +
                    aggregatedData.uncollectedTaxes[1].tax +
                    aggregatedData.uncollectedTaxes[2].tax
                )
              }}
            </li>
          </ul>
        </v-col>
      </v-row>

      <v-row class="quarter-item">
        <v-col pt-5 pb-5 class="col-12">
          <h3 class="section-title red-text">
            <span>Struttura ricettiva</span>
          </h3>
          <p>
            Riepilogo pernottamenti con imposta a carico della struttura
            ricettiva
          </p>
        </v-col>
        <v-col cols="3">
          <h3 class="month-header">Mese</h3>

          <ul>
            <li class="month-item small">
              {{ getMonthLabel(0) }}
            </li>
            <li class="month-item small">
              {{ getMonthLabel(1) }}
            </li>
            <li class="month-item small">
              {{ getMonthLabel(2) }}
            </li>
          </ul>
        </v-col>
        <v-col cols="4">
          <h3 class="month-header">N° pernottamenti per mese</h3>
          <ul>
            <li class="month-item">
              <input
                type="number"
                :value="aggregatedData.collectedTaxes[0].stays"
                @input="
                  setStays(
                    aggregatedData.collectedTaxes,
                    0,
                    $event.target.value
                  )
                "
                :disabled="!editMode"
              />
            </li>
            <li class="month-item">
              <input
                type="number"
                :value="aggregatedData.collectedTaxes[1].stays"
                @input="
                  setStays(
                    aggregatedData.collectedTaxes,
                    1,
                    $event.target.value
                  )
                "
                :disabled="!editMode"
              />
            </li>
            <li class="month-item">
              <input
                type="number"
                :value="aggregatedData.collectedTaxes[2].stays"
                @input="
                  setStays(
                    aggregatedData.collectedTaxes,
                    2,
                    $event.target.value
                  )
                "
                :disabled="!editMode"
              />
            </li>
          </ul>
        </v-col>
        <v-col cols="5">
          <h3 class="month-header">Importo per mese</h3>
          <ul>
            <li class="month-item">
              <span>€ </span>
              <input
                type="number"
                min="0"
                step="0.01"
                :value="aggregatedData.collectedTaxes[0].tax / 100"
                @input="
                  setPrice(
                    aggregatedData.collectedTaxes,
                    0,
                    $event.target.value
                  )
                "
                :disabled="!editMode"
              />
            </li>
            <li class="month-item">
              <span>€ </span>
              <input
                type="number"
                min="0"
                step="0.01"
                :value="aggregatedData.collectedTaxes[1].tax / 100"
                @input="
                  setPrice(
                    aggregatedData.collectedTaxes,
                    1,
                    $event.target.value
                  )
                "
                :disabled="!editMode"
              />
            </li>
            <li class="month-item">
              <span>€ </span>
              <input
                type="number"
                min="0"
                step="0.01"
                :value="aggregatedData.collectedTaxes[2].tax / 100"
                @input="
                  setPrice(
                    aggregatedData.collectedTaxes,
                    2,
                    $event.target.value
                  )
                "
                :disabled="!editMode"
              />
            </li>
          </ul>
        </v-col>
        <v-col cols="5">
          <h3 class="month-header">TOT. N° PERNOTT. PAGANTI *</h3>
          <ul>
            <li class="month-item">
              {{ aggregatedData.collectedTaxes.total.stays }}
            </li>
          </ul>
        </v-col>
        <v-col cols="2"></v-col>
        <v-col cols="5">
          <h3 class="month-header">TOTALE IMPOSTA **</h3>
          <ul>
            <li class="month-item">
              {{
                getPrice(
                  aggregatedData.collectedTaxes[0].tax +
                    aggregatedData.collectedTaxes[1].tax +
                    aggregatedData.collectedTaxes[2].tax
                )
              }}
            </li>
          </ul>
        </v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col>
          <p class="mb-0">
            * Il numero di pernottamenti paganti è pari al numero dei
            pernottamenti detratti i pernottamenti esenti
          </p>
          <p>
            ** Il totale dell'imposta riscossa è pari a quanto verrà riversato
            per il trimestre al Comune e si riferisce al tributo dei
            pernottamenti paganti
          </p>

          <hr class="my-8" />
        </v-col>
      </v-row>
      <v-row class="quarter-item">
        <v-col>
          <h3 class="month-header">Eventuali note</h3>
          <textarea
            v-model="aggregatedData.notes"
            rows="3"
            class="block"
            style="width: 100%"
          ></textarea>
        </v-col>
      </v-row>
      <v-row class="quarter-item">
        <v-col>
          <v-btn
            elevation="2"
            color="#ec2b3c"
            class="white--text send logout"
            @click="download"
            >{{ editMode ? "Salva ed esporta PDF" : "Esporta PDF" }}</v-btn
          >
          <br /><br /><br /><br />
        </v-col>
      </v-row>
    </v-container>
    <v-alert
      color="#e86a20"
      dark
      icon="mdi-close-thick"
      border="left"
      prominent
      dismissible
      v-model="downloadFailureAlert"
    >
      Il download del file è fallito, controlla i campi di selezione e riprova.
    </v-alert>
    <v-alert color="#4c4c4c" dark border="left" v-model="downloadLoadingAlert">
      <v-row class="align-center">
        <v-col cols="2">
          <v-progress-circular
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-col>
        <v-col cols="10">
          Download del file in corso...
        </v-col>
      </v-row>
    </v-alert>
  </section>
</template>

<script>
import moment from "moment";
import LogoutButton from "@/components/LogoutButton.vue";
import CtaButton from "@/components/CtaButton.vue";
import { exemptions } from "../data/exemptions";

export default {
  components: {
    CtaButton,
    LogoutButton
  },

  data() {
    return {
      aggregatedData: null,
      exemptions,
      editMode: false,
      downloadFailureAlert: false,
      downloadLoadingAlert: false
    };
  },

  computed: {
    year() {
      const date = moment([this.$route.query.year, 0, 1]);
      if (date.isValid()) {
        return date.year();
      }

      return moment().year();
    },
    quarter() {
      return Number(this.$route.query.quarter) || 1;
    }
  },

  created() {
    this.getExportPreview();
  },

  methods: {
    async getExportPreview() {
      this.$root.alert("Caricamento anteprima PDF in corso...", "progress");

      try {
        const { data } = await this.$axios.post(
          `/api/v1/facilities/${this.$route.params.facilityId}/exports/QuarterlyMunicipalityReportingPdf/preview`,
          {
            year: this.year,
            quarter: this.quarter
          }
        );
        this.$set(this, "aggregatedData", data.result);
      } catch (err) {
        this.$router.push("/not-found");
      }

      this.$root.hideAlert();
    },
    async download() {
      this.downloadFailureAlert = false;
      this.downloadLoadingAlert = true;

      try {
        const { data, headers } = await this.$axios.post(
          `/api/v1/facilities/${this.$route.params.facilityId}/exports/QuarterlyMunicipalityReportingPdf/download`,
          {
            year: this.year,
            quarter: this.quarter,
            data: this.aggregatedData
          },
          {
            responseType: "blob"
          }
        );

        const [, filename] = headers["content-disposition"].split("=");
        const url = window.URL.createObjectURL(data);
        const a = document.createElement("a");

        a.style.display = "none";
        a.href = url;
        a.download = filename;

        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        this.downloadLoadingAlert = false;
      } catch (err) {
        this.downloadLoadingAlert = false;
        this.downloadFailureAlert = true;
      }
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    setPrice(obj, index, value) {
      obj[index].tax = (Number(value) || 0) * 100;

      obj.total.tax = Math.round(obj[0].tax + obj[1].tax + obj[2].tax);
    },
    getMonthLabel(month) {
      const m = (this.quarter - 1) * 3 + month;
      const label = moment([2020, m, 1])
        .locale("it")
        .format("MMMM");

      return label[0].toUpperCase() + label.slice(1);
    },
    getPrice(value) {
      return "€" + (value / 100).toFixed(2).replace(".", ",");
    },
    showTotal(value1, value2, value3) {
      return (
        (Number(value1) || 0) + (Number(value2) || 0) + (Number(value3) || 0)
      );
    },
    setStays(obj, index, value) {
      obj[index].stays = Number(value);
      obj.total.stays = obj[0].stays + obj[1].stays + obj[2].stays;
    }
  }
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1900px) {
  .container {
    max-width: 1040px;
  }
}

.title-container {
  h1 {
    color: #002762;
  }
}

.mini-title {
  background-color: #002762;
}

.sub-title {
  background-color: #002762;
  color: #fff;
  padding: 16px;
  font-size: 18px;
  margin-bottom: 16px;
}

.summary-item {
  h4 {
    color: #002762;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  p {
    font-size: 26px;
    margin-bottom: 20px;
    min-height: 39px;
  }
}

.section-title {
  color: #002762;
  text-transform: uppercase;
  position: relative;
  margin-top: 20px;

  span {
    display: inline-block;
    padding-right: 16px;
    background: #fff;
    position: relative;
    z-index: 1;
  }

  &.red-text {
    color: #ec2b3c;
  }

  &:after {
    content: "";
    position: absolute;
    height: 1px;
    background: #e0e0e0;
    top: 50%;
    left: 0;
    right: 0;
    z-index: 0;
  }
}

.exemption-item {
  h4 {
    text-transform: uppercase;
    font-weight: bold;
  }

  p {
    font-size: 18px;
    font-weight: 300;
    margin: 0;
  }

  input {
    font-size: 30px;
    display: block;
    width: 100%;
    padding: 0 4px;
    border: 2px solid #fff;
  }
}

.quarter-item {
  .month-header {
    color: #002762;
    text-transform: uppercase;
    font-size: 16px;
    min-height: 48px;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  .month-item {
    font-size: 30px;
    input {
      font-size: 30px;
      width: 120px;
      padding: 0 4px;
      border: 2px solid #fff;
    }

    &.small {
      transform: scale(0.65);
      transform-origin: left center;
      font-weight: bold;
    }
  }

  .gray {
    .month-item {
      color: #ccc;

      input {
        color: #ccc;
      }
    }
  }
}

textarea {
  font-size: 30px;
  padding: 4px;
}

.edit-mode {
  input,
  textarea {
    background: #f5f4f4;
  }
}

.v-alert {
  position: fixed;
  margin: 0;
  bottom: 30px;
  right: 30px;
}
</style>
