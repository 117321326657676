<template>
  <section>
    <v-row>
      <v-col class="col-md-3 col-12 pb-0">
        <label for="room-name" class="font-weight-bold tourist-tax-pro-blue"
          >Nome Camera</label
        >
        <v-text-field
          v-model="nameModel"
          label="Inserisci"
          :rules="[v => !!v || 'Campo obbligatorio']"
          required
          filled
          dense
          color="#002762"
          id="room-name"
        ></v-text-field>
      </v-col>
      <v-col class="col-md-3 col-12 pb-0">
        <p for="n-paying-guests" class="font-weight-bold tourist-tax-pro-blue">
          Numero Ospiti Paganti: {{ numeroOspitiPaganti }}
        </p>
        <!-- <v-text-field
          v-model="numeroOspitiPaganti"
          required
          filled
          dense
          readonly
          color="#002762"
          id="n-paying-guests"
        ></v-text-field> -->
      </v-col>
      <v-col class="col-md-3 col-12 pb-0">
        <p for="n-free-guests" class="font-weight-bold tourist-tax-pro-blue">
          Numero Ospiti Esenti: {{ numeroOspitiEsenti }}
        </p>
        <!-- <v-text-field
          v-model="numeroOspitiEsenti"
          required
          filled
          dense
          readonly
          color="#002762"
          id="n-free-guests"
        ></v-text-field> -->
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  props: {
    room: {
      type: Object,
      required: true
    }
  },
  computed: {
    nameModel: {
      get() {
        return this.room.name;
      },
      set(value) {
        this.$emit("setName", value);
      }
    },
    numeroOspitiPaganti() {
      // let n_paganti = 0;
      // for (let index = 0; index < this.room.guests.length; index++) {
      //   const guest = this.room.guests[index];
      //   if (!guest.exemption) {
      //     n_paganti++;
      //   }
      // }
      // return n_paganti;

      // guest e' una nuova variabile, callback
      return this.room.guests.filter(guest => !guest.exemption).length;
    },
    numeroOspitiEsenti() {
      return this.room.guests.filter(guest => guest.exemption).length;
    }
  }
};
</script>

<style></style>
