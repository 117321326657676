<template>
  <v-row>
    <!-- first row form START -->

    <v-col cols="12">
      <label for="username" class="font-weight-bold">Stato servizio</label>
      <h2 v-show="enabled" class="text-uppercase green--text">attivo</h2>
      <h2 v-show="!enabled" class="text-uppercase red--text">inattivo</h2>
    </v-col>

    <v-col cols="12" md="6">
      <label for="username" class="font-weight-bold">{{
        fields.username.name
      }}</label>
      <v-text-field
        required
        label="Inserisci"
        filled
        v-model="result.username"
        dense
        color="#002762"
        id="username"
      ></v-text-field>
      <span class="description">{{ fields.username.description }}</span>
    </v-col>

    <v-col cols="12" md="6">
      <label for="password" class="font-weight-bold">{{
        fields.password.name
      }}</label>
      <v-text-field
        label="Inserisci"
        required
        filled
        v-model="result.password"
        type="password"
        dense
        color="#002762"
        id="password"
      ></v-text-field>
      <span class="description">{{ fields.password.description }}</span>
    </v-col>
    <!-- first row form END -->

    <!-- second row form START -->
    <v-col cols="12" md="6">
      <label for="cir" class="font-weight-bold">{{ fields.code.name }}</label>
      <v-text-field
        required
        filled
        v-model="result.code"
        label="Inserisci"
        dense
        color="#002762"
        id="cir"
      ></v-text-field>
      <span class="description">{{ fields.code.description }}</span>
    </v-col>

    <v-col cols="12" md="6">
      <v-checkbox v-model="result.checked" color="#002762">
        <template v-slot:label>
          <div>
            Dichiaro di avere letto e accettato la
            <template>
              <a
                target="_blank"
                href="https://www.visitbergamo.net/media/file/delega_e_trattamento_dati.pdf"
                @click.stop
              >
                delega all'invio dei flussi turistici da parte di VisitBergamo
              </a>
            </template>
          </div>
        </template>
        "Dichiaro di avere letto e accettato la
      </v-checkbox>
    </v-col>
    <!-- second row form END -->

    <v-row class="button-form pt-10 pb-10 ml-5 mr-5">
      <v-col class="d-flex justify-space-between col-10 col-md-4">
        <CtaButton @click.native="cancel()">annulla</CtaButton>
        <CtaButton @click.native="update()">salva</CtaButton>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import CtaButton from "@/components/CtaButton.vue";

export default {
  components: {
    CtaButton
  },
  data() {
    return {
      enabled: false,
      result: {
        checked: false,
        code: "",
        password: "",
        username: ""
      },
      fields: {
        checked: {
          name: "",
          type: ""
        },
        code: {
          description: "",
          name: ""
        },
        password: {
          description: "",
          name: ""
        },
        username: {
          description: "",
          name: ""
        }
      }
    };
  },
  created() {
    this.fieldsIntegrations();
    this.dataIntegrations();
  },
  methods: {
    async fieldsIntegrations() {
      try {
        const { data } = await this.$axios.get(
          `/api/v1/facilities/${this.$route.params.facilityId}/integrations`
        );
        this.enabled = data.result.Turismo5.enabled;
        this.fields = data.result.Turismo5.fields;
      } catch (err) {
        return err;
      }
    },
    async dataIntegrations() {
      try {
        const { data } = await this.$axios.get(
          `/api/v1/facilities/${this.$route.params.facilityId}/integrations/Turismo5`
        );
        // console.log(data.result.fields);
        this.result = data.result.data;
        this.fields = data.result.fields;
      } catch (err) {
        return err;
      }
    },
    async update() {
      this.$root.alert("Salvataggio in corso...", "progress");
      try {
        const result = await this.$axios.put(
          `/api/v1/facilities/${this.$route.params.facilityId}/integrations/Turismo5`,
          this.result
        );

        this.$root.alert("Struttura aggiornata correttamente");

        return result;
      } catch (err) {
        console.log(err.response);
      }
    },
    cancel() {
      window.location.reload(true);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-input--checkbox {
  height: 30px !important;
}

label {
  color: #002762;
}

.description {
  opacity: 0.5;
}
</style>
