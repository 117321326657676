<template>
  <v-row class="linked-users">
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Nome
                    </th>
                    <th class="text-left">
                      Cognome
                    </th>
                    <th class="text-left">
                      Email
                    </th>
                    <th class="text-left">
                      Azioni
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user of users" :key="user.id">
                    <td>{{ user.name }}</td>
                    <td>{{ user.surname }}</td>
                    <td>{{ user.email }}</td>
                    <td>
                      <CtaButton @click="removeUser(user)">
                        Rimuovi
                      </CtaButton>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <UserSelect
            v-model="newUser"
            label="Aggiungi utente"
            ref="userSelect"
          />
          <small>NB: Non è necessario associare utenti amministratori</small>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import UserSelect from "./UserSelect.vue";
import CtaButton from "@/components/CtaButton.vue";

export default {
  components: {
    UserSelect,
    CtaButton
  },
  data() {
    return {
      newUser: null,
      users: []
    };
  },
  computed: {
    facilityId() {
      return this.$route.params.facilityId;
    }
  },
  watch: {
    newUser() {
      if (!this.newUser) {
        return;
      }

      this.addUser();
    }
  },
  created() {
    this.getUsers();
  },
  methods: {
    async getUsers() {
      try {
        const { data } = await this.$axios.get(
          `/api/v1/facilities/${this.facilityId}/users`
        );

        this.users = data.results;
      } catch (err) {
        // Do nothing
      }
    },
    async addUser() {
      try {
        await this.$axios.put(
          `/api/v1/facilities/${this.facilityId}/users/${this.newUser}`
        );

        await this.getUsers();

        this.newUser = null;
        this.$refs.userSelect.clear();
      } catch (err) {
        // Do nothing
      }
    },
    async removeUser(user) {
      if (
        !confirm(
          `Sei sicuro di voler rimuovere l'utente ${user.name} ${user.surname}?`
        )
      ) {
        return;
      }

      try {
        await this.$axios.delete(
          `/api/v1/facilities/${this.facilityId}/users/${user.id}`
        );

        await this.getUsers();

        this.newUser = null;
      } catch (err) {
        // Do nothing
      }
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  color: #002762;
}

.description {
  opacity: 0.5;
}
</style>
