<template>
  <section>
    <v-container>
      <v-row class="row mb-10 mt-10 d-flex pl-3 pr-3 justify-space-between">
        <router-link to="/" class="text-decoration-none">
          <CtaButton>Indietro</CtaButton>
        </router-link>
        <LogoutButton />
      </v-row>
      <v-form ref="form">
        <v-row justify-center>
          <v-col cols="12">
            <AnagraficaForm :edit="false" />
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </section>
</template>

<script>
import LogoutButton from "@/components/LogoutButton.vue";
import CtaButton from "@/components/CtaButton.vue";
import AnagraficaForm from "@/components/AnagraficaForm.vue";

export default {
  components: {
    CtaButton,
    LogoutButton,
    AnagraficaForm
  },
  data() {
    return {
      tab: null,
      voices: [{ tab: "Anagrafica", content: AnagraficaForm, admin: true }],
      isDisabled: false
    };
  },

  methods: {
    admin() {
      return this.$auth.user["https://www.visitbergamo.net"].roles.includes(
        "ttp_admin"
      );
    }
  }
};
</script>

<style lang="scss">
@media screen and (min-width: 1900px) {
  .container {
    max-width: 1040px;
  }
}

.v-item-group {
  width: 100%;
}

.button-form {
  justify-content: space-evenly;
}
.v-slide-group__prev {
  display: none !important;
}

.v-tabs-bar {
  border-radius: inherit;
  height: 70px !important;
}
</style>
