<template>
  <v-btn
    elevation="2"
    color="#ec2b3c"
    class="white--text send logout"
    @click="logout"
    >Log out</v-btn
  >
</template>

<script>
import Cookies from "js-cookie";
export default {
  methods: {
    logout() {
      Cookies.remove("auth_token");
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.send {
  font-size: clamp(0.7rem, 1vw, 1rem);
}
</style>
