<template>
  <section>
    <v-container>
      <v-row class="row mb-10 mt-10 d-flex pl-3 pr-3 justify-space-between">
        <router-link to="/show" class="text-decoration-none">
          <CtaButton>Indietro</CtaButton>
        </router-link>
        <LogoutButton />
      </v-row>
      <v-form ref="form">
        <v-row justify-center>
          <v-col cols="12">
            <v-tabs
              v-model="tab"
              background-color="transparent"
              color="#002762"
              class="mb-10"
              :fixed-tabs="this.admin()"
              :grow="!this.admin()"
            >
              <v-tab
                v-for="voice in voices"
                :key="voice.tab"
                :disabled="isDisabled"
                v-show="voice.admin"
              >
                <div>
                  {{ voice.tab }}
                </div>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" full-width>
              <v-tab-item v-for="voice in voices" :key="voice.tab" full-width>
                <component :is="voice.content" v-if="voice.admin"></component>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </section>
</template>

<script>
import LogoutButton from "@/components/LogoutButton.vue";
import CtaButton from "@/components/CtaButton.vue";
import AnagraficaForm from "@/components/AnagraficaForm.vue";
import Turismo5Form from "@/components/Turismo5Form.vue";
import ComuneForm from "@/components/ComuneForm.vue";
import LinkedUsers from "@/components/LinkedUsers.vue";

export default {
  components: {
    CtaButton,
    LogoutButton,
    AnagraficaForm,
    Turismo5Form,
    ComuneForm,
    LinkedUsers
  },
  data() {
    return {
      tab: null,
      voices: [
        { tab: "Anagrafica", content: AnagraficaForm, admin: true },
        { tab: "Ross1000", content: Turismo5Form, admin: true },
        {
          tab: "Rendicontazione comune trimestrale",
          content: ComuneForm,
          admin: this.admin()
        },
        {
          tab: "Utenti",
          content: LinkedUsers,
          admin: this.admin()
        }
      ],
      isDisabled: false
    };
  },
  created() {
    this.loadIntegrations();
  },

  methods: {
    admin() {
      return this.$auth.user["https://www.visitbergamo.net"].roles.includes(
        "ttp_admin"
      );
    },
    async loadIntegrations() {
      try {
        const { data } = await this.$axios.get(
          `/api/v1/facilities/${this.$route.params.facilityId}/integrations`
        );
        if (!Object.keys(data.result).length) {
          this.isDisabled = true;
        }
        return data;
      } catch (err) {
        return err;
      }
    }
  }
};
</script>

<style lang="scss">
@media screen and (min-width: 1900px) {
  .container {
    max-width: 1040px;
  }
}

.v-item-group {
  width: 100%;
}

.button-form {
  justify-content: space-evenly;
}
.v-slide-group__prev {
  display: none !important;
}

.v-tabs-bar {
  border-radius: inherit;
  height: 70px !important;
}
</style>
