<template>
  <div>
    <label
      for="period-month"
      class="font-weight-bold tourist-tax-pro-blue text-uppercase"
      >seleziona mese</label
    >
    <v-menu v-model="menuModel" :close-on-content-click="false" max-width="290">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="monthLabel"
          readonly
          required
          filled
          color="#002762"
          v-bind="attrs"
          v-on="on"
          append-icon="mdi-calendar"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="monthModel"
        type="month"
        locale="it"
        color="#002762"
        @change="menuModel = false"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },

  data() {
    return {
      menuModel: false
    };
  },

  computed: {
    monthModel: {
      get() {
        return moment([this.value.year, this.value.month, 1]).format(
          "YYYY-MM-DD"
        );
      },
      set(value) {
        this.$emit("input", {
          month: moment(value).month(),
          year: moment(value).year()
        });
      }
    },
    monthLabel() {
      return moment([this.value.year, this.value.month, 1])
        .locale("it")
        .format("MMMM YYYY");
    }
  }
};
</script>

<style lang="scss" scoped></style>
