<template>
  <v-form ref="form">
    <v-row>
      <!-- first row form START -->
      <v-col cols="12" md="6">
        <FacilitySelect
          v-model="strutturaRicettiva"
          :disabled="!!submissionUpdate"
        />
      </v-col>

      <v-col cols="12" md="6">
        <label for="n-ricevuta" class="font-weight-bold">Numero Ricevuta</label>
        <v-text-field
          v-model="submission.receipt_number"
          label="Inserisci"
          :disabled="submission.broker == 'airbnb'"
          :hint="
            submission.broker == 'airbnb'
              ? 'Ricevuta a carico del portale telematico'
              : ''
          "
          :persistent-hint="submission.broker == 'airbnb'"
          filled
          dense
          color="#002762"
          id="n-ricevuta"
        ></v-text-field>
      </v-col>
      <!-- first row form END -->

      <!-- second row form START -->
      <v-col cols="12" md="6">
        <v-menu
          v-model="menuDateSoggiorno"
          ref="menuDateSoggiorno"
          :close-on-content-click="false"
          :return-value.sync="submission.dateSoggiornoArray"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <label for="soggiorno" class="font-weight-bold"
              >Date di check-in e check-out</label
            >
            <v-text-field
              v-model="dateSoggiornoFormatted"
              id="soggiorno"
              label="Seleziona Date"
              color="#002762"
              :value="dateSoggiornoArray"
              readonly
              required
              :rules="[v => !!v || 'Campo obbligatorio']"
              filled
              append-icon="mdi-calendar"
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateSoggiornoValue"
            elevation="10"
            :title-date-format="titleSoggiornoFormatted"
            locale="it"
            color="#002762"
            range
          >
            <v-spacer></v-spacer>
            <v-btn text color="#ec2b3c" @click="menuDateSoggiorno = false">
              Cancella
            </v-btn>
            <v-btn
              text
              color="#002762"
              @click="$refs.menuDateSoggiorno.save(dateSoggiornoArray)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="6">
        <v-menu
          v-model="menuDataRicevuta"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <label for="data-ricevuta" class="font-weight-bold"
              >Data Ricevuta</label
            >
            <v-text-field
              v-model="DateRicevutaFormatted"
              :value="submission.receipt_date"
              id="data-ricevuta"
              label="Seleziona Data"
              color="#002762"
              :disabled="submission.broker == 'airbnb'"
              :hint="
                submission.broker == 'airbnb'
                  ? 'Ricevuta a carico del portale telematico'
                  : ''
              "
              :persistent-hint="submission.broker == 'airbnb'"
              filled
              readonly
              dense
              append-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="submission.receipt_date"
            color="#002762"
            locale="it"
            @input="menuDataRicevuta = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <!-- second row form END -->

      <!-- third row form START -->
      <v-col cols="12" md="6">
        <label for="intermediary" class="font-weight-bold">Intermediario</label>
        <v-select
          id="intermediary"
          label="Seleziona Intermediario"
          :items="brokers"
          v-model="submission.broker"
          filled
          :rules="[v => !!v || 'Campo obbligatorio']"
          required
          dense
          color="#002762"
        ></v-select>
        <p class="text-body-2">
          ATTENZIONE è importante indicare se il tuo ospite ha prenotato
          attraverso il portale Airbnb al fine di mantenere esatta e coerente la
          rendicontazione dell'imposta di soggiorno al Comune di Bergamo.
          L'onere dell'incasso e del versamento dell'imposta è a carico di
          Airbnb.
        </p>
      </v-col>

      <v-col cols="12" md="6">
        <label for="costo-pernottamento" class="font-weight-bold"
          >Costo pernottamento</label
        >
        <v-text-field
          id="costo-pernottamento"
          prefix="€"
          type="number"
          v-model="total"
          :rules="[v => !!v || 'Campo obbligatorio']"
          color="#002762"
          required
          filled
          dense
        ></v-text-field>
        <v-radio-group class="mt-0" row v-model="stay">
          <v-radio
            label="Totale per soggiorno"
            color="#002762"
            class="mb-0"
            checked
          ></v-radio>
          <v-radio label="Per singolo pernottamento" color="#002762">
            <!-- moltiplico per notti -->
          </v-radio>
        </v-radio-group>
        <p class="text-body-2">
          Inserire il costo dell'intero periodo o il costo del singolo
          pernottamento comprensivo di eventuali servizi (prima colazione,
          servizi pulizia, commissioni portali di intermediazione, etc)
        </p>
      </v-col>
      <!-- third row form END -->
    </v-row>

    <!-- <DatiOccupanti /> -->
    <DatiOccupanti
      v-model="submission.rooms"
      @update-guest="updateGuest($event)"
      @add-room="addRoom()"
      @add-guest="addGuest($event)"
      @delete-guest="deleteGuest($event)"
      @delete-room="deleteRoom($event)"
      :checkin="submission.checkin"
      :checkout="submission.checkout"
    />

    <v-row>
      <v-col cols="12" md="6" offset-md="6">
        <!-- <div
          class="d-flex justify-space-between"
          :class="{ opacity: !collect }"
        >
          <p class="text-uppercase font-weight-bold text-body-2">
            totale tassa per persona
          </p>
          <span class="font-weight-bold text-body-2">
            {{ tax_per_person }}
          </span>
        </div> -->
        <div
          class="d-flex justify-space-between"
          :class="{ opacity: !collect }"
        >
          <p class="font-weight-bold text-h5">
            Totale
          </p>
          <span class="font-weight-bold text-h5">
            {{ tax_total_amount }}
          </span>
        </div>
        <div v-if="!collect">
          <p class="font-weight-light">
            L’importo della tassa di soggiorno viene indicato a mero titolo
            informativo in quanto l’onere dell’incasso e del versamento
            dell’imposta è in capo a Airbnb. Il dato è registrato ai fini della
            rendicontazione trimestrale.
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row class="button-form pt-10 pb-10 ml-5 mr-5">
      <v-col class="d-flex justify-space-between col-12 col-md-4">
        <CtaButton @click="cancel()">annulla</CtaButton>
        <CtaButton v-if="!this.submissionUpdate" @click.native="save()"
          >salva</CtaButton
        >
        <CtaButton v-if="this.submissionUpdate" @click.native="update()"
          >aggiorna</CtaButton
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import CtaButton from "@/components/CtaButton.vue";
import DatiOccupanti from "@/components/DatiOccupanti.vue";
import moment from "moment";
import FacilitySelect from "@/components/FacilitySelect.vue";

export default {
  components: {
    CtaButton,
    DatiOccupanti,
    FacilitySelect
  },
  props: {
    submissionUpdate: {
      type: Object,
      required: false,
      default: null
    },
    facilityId: {
      type: String,
      default: null
    }
  },
  data: () => ({
    submission: {
      checkin: "",
      checkout: "",
      total_price_cents: 0,
      broker: "",
      receipt_number: "",
      receipt_date: "",
      rooms: []
    },
    dateSoggiornoArray: [],
    strutturaRicettiva: "",
    stay: 0,
    total: 0,
    collect: true,
    tax_per_person: "€ " + 0,
    tax_total_amount: "€ " + 0,
    menuDateSoggiorno: false,
    menuDataRicevuta: false,
    facilities: [],
    brokers: [
      {
        value: "airbnb",
        text: "Airbnb",
        airbnb: {
          collectTax: false
        }
      },
      {
        value: "direct",
        text: "Prenotazione diretta",
        direct: {
          collectTax: true
        }
      },
      {
        value: "booking",
        text: "Booking.com",
        booking: {
          collectTax: true
        }
      },
      {
        value: "expedia",
        text: "Expedia",
        expedia: {
          collectTax: true
        }
      },
      {
        value: "trivago",
        text: "Trivago",
        trivago: {
          collectTax: true
        }
      },
      {
        value: "homeway",
        text: "Homeway",
        homeway: {
          collectTax: true
        }
      },
      {
        value: "other",
        text: "Altro",
        other: {
          collectTax: true
        }
      }
    ]
  }),
  watch: {
    dateSoggiornoArray() {
      this.submission.checkin = this.dateSoggiornoArray[0];
      this.submission.checkout = this.dateSoggiornoArray[1];
    },
    stay() {
      this.totaleRoom();
    },
    total() {
      this.totaleRoom();
    },
    submission: {
      // guarda il contenuto dell'array e lo stato intero dell'array
      deep: true,
      handler() {
        this.totalCalc();
      }
    }
  },
  async created() {
    if (this.submissionUpdate) {
      this.strutturaRicettiva = this.submissionUpdate.facility_id;
      this.submission.receipt_number = this.submissionUpdate.receipt_number;
      this.dateSoggiornoArray = [
        moment(this.submissionUpdate.checkin).format("YYYY-MM-DD"),
        moment(this.submissionUpdate.checkout).format("YYYY-MM-DD")
      ];
      this.submission.receipt_date = this.submissionUpdate.receipt_date
        ? moment(this.submissionUpdate.receipt_date).format("YYYY-MM-DD")
        : null;
      this.submission.broker = this.submissionUpdate.broker;
      this.total = (this.submissionUpdate.total_price_cents / 100).toFixed(2);
      this.submission.rooms = this.submissionUpdate.rooms;
    } else {
      this.addRoom();
    }

    this.facilities = (await this.getStructures()).map(facility => {
      return {
        text: facility.name,
        value: facility.id
      };
    });
  },
  methods: {
    async save() {
      try {
        this.$root.alert("Salvataggio in corso...", "progress");
        const result = await this.$axios.post(
          `/api/v1/facilities/${this.strutturaRicettiva}/submissions`,
          this.submission
        );

        this.$router.push({
          name: "ManageExport",
          query: {
            facility: this.strutturaRicettiva,
            year: moment(this.submission.checkin).year(),
            month: moment(this.submission.checkin).month()
          }
        });

        this.$root.alert("Pernottamento salvato correttamente");
        return result;
      } catch (err) {
        if (err.response.data.status === 404) {
          this.$root.alert("È necessario selezionare una struttura", "error");
        } else {
          this.$root.alert(
            this.formValidation(err)
              .map(error => error)
              .join("<br>"),
            "error"
          );
        }
      }
    },
    async update() {
      try {
        this.$root.alert("Salvataggio in corso...", "progress");
        const result = await this.$axios.put(
          `/api/v1/facilities/${this.strutturaRicettiva}/submissions/${this.submissionUpdate.id}`,
          this.submission
        );

        this.$router.push({
          name: "ManageExport",
          query: {
            facility: this.strutturaRicettiva,
            year: moment(this.submission.checkin).year(),
            month: moment(this.submission.checkin).month()
          }
        });

        this.$root.alert("Pernottamento aggiornato correttamente");

        return result;
      } catch (err) {
        if (err.response.data.status === 404) {
          this.$root.alert("È necessario selezionare una struttura", "error");
        } else {
          this.$root.alert(
            this.formValidation(err)
              .map(error => error)
              .join("<br>"),
            "error"
          );
        }
      }
    },
    async getStructures() {
      try {
        const result = await this.$axios.get(`/api/v1/facilities`);
        return result.data.results;
      } catch (err) {
        return err;
      }
    },
    async totalCalc() {
      if (!this.strutturaRicettiva) {
        return;
      }

      try {
        const result = await this.$axios.post(
          `/api/v1/facilities/${this.strutturaRicettiva}/submissions/calc`,
          this.submission
        );
        this.tax_total_amount = result.data.result.tax_total_amount;
        this.tax_per_person = result.data.result.tax_per_person;
        this.collect = result.data.result.collect;
      } catch (err) {
        return err;
      }
    },
    addRoom() {
      this.submission.rooms.push({
        name: "",
        id: Math.random(),
        guests: []
      });
      this.addGuest(this.submission.rooms.length - 1);
    },
    addGuest(index) {
      this.submission.rooms[index].guests.push({
        checkin: this.submission.checkin
          ? moment(this.submission.checkin)
          : null,
        checkout: this.submission.checkout
          ? moment(this.submission.checkout)
          : null,
        id: Math.random(),
        country: "",
        region: "",
        exemption: ""
      });
    },
    deleteGuest(event) {
      this.submission.rooms[event.roomIndex].guests.splice(event.guestIndex, 1);
    },
    deleteRoom(event) {
      this.submission.rooms.splice(event, 1);
    },
    updateGuest(event) {
      const guest = this.submission.rooms[event.roomIndex].guests[
        event.guestIndex
      ];

      Object.keys(event.guestData).forEach(key => {
        guest[key] = event.guestData[key];
      });
    },
    totaleRoom() {
      if (this.stay === 0) {
        this.submission.total_price_cents = Math.floor(this.total * 100);
      } else {
        this.submission.total_price_cents =
          Math.floor(this.total * 100) * this.giornoSoggiorno;
      }
    },
    cancel() {
      window.location.reload(true);
    },
    createMessage(message, parts) {
      let roomNumber = undefined;
      let guestNumber = undefined;
      const depth = parts.filter(part => !isNaN(+part));
      roomNumber = Number(depth[0]);
      guestNumber = Number(depth[1]);

      let formattedMessage = `${message}`;

      if (!isNaN(Number(depth[0]))) {
        formattedMessage = `${message} della camera ${roomNumber + 1}`;
      }
      if (!isNaN(Number(depth[1]))) {
        formattedMessage = `${message} per l'ospite ${guestNumber +
          1} della camera ${roomNumber + 1}`;
      }

      return formattedMessage;
    },
    formValidation(err) {
      const errors = [];

      for (const { field, validation, details, message } of err.response.data
        .errors) {
        if (field) {
          const parts = field.split(".");

          if (validation == "required") {
            switch (parts[parts.length - 1]) {
              case "receipt_number":
                errors.push("È necessario inserire il numero di ricevuta");
                break;
              case "checkin":
                errors.push("È necessario inserire una data checkin");
                break;
              case "checkout":
                errors.push("È necessario inserire una data checkout");
                break;
              case "broker":
                errors.push("È necessario inserire un intermediario");
                break;
              case "name":
                errors.push(
                  this.createMessage("È necessario inserire il nome", parts)
                );
                break;
              case "country":
                errors.push(
                  this.createMessage(
                    "È necessario inserire la nazionalità",
                    parts
                  )
                );
                break;

              default:
                break;
            }
          } else if (validation == "availableRooms") {
            switch (parts[parts.length - 1]) {
              case "rooms":
                errors.push(
                  "Non sono presenti camere disponibili per questa struttura"
                );
                break;
              default:
                break;
            }
          } else if (validation == "requiredWhenSubfield") {
            switch (parts[parts.length - 1]) {
              case "region":
                errors.push(
                  this.createMessage("È necessario inserire la regione", parts)
                );
                break;
              default:
                break;
            }
          } else if (validation == "above") {
            switch (parts[parts.length - 1]) {
              case "total_price_cents":
                errors.push("Inserire un prezzo superiore a € 0");
                break;
              default:
                break;
            }
          } else if (validation == "min") {
            switch (parts[parts.length - 1]) {
              case "rooms":
                errors.push("Numero di camere non valido");
                break;
              case "guests":
                errors.push("Numero di ospiti non valido");
                break;
              default:
                break;
            }
          } else {
            errors.push(message);
          }
        } else {
          if (validation == "closingDayCollision") {
            errors.push(`Data di chiusura il ${details.date}`);
          }
        }
      }

      return errors;
    },
    titleSoggiornoFormatted() {
      let dateSoggiornoArrayFormatted = [];
      for (let i = 0; i < this.dateSoggiornoArray.length; i++) {
        dateSoggiornoArrayFormatted.push(
          moment(this.dateSoggiornoArray[i]).format("DD/MM")
        );
      }
      return dateSoggiornoArrayFormatted.join(" - ");
    }
  },
  computed: {
    dateSoggiornoFormatted() {
      let dateSoggiornoArrayFormatted = [];
      for (let i = 0; i < this.dateSoggiornoArray.length; i++) {
        dateSoggiornoArrayFormatted.push(
          moment(this.dateSoggiornoArray[i]).format("DD/MM")
        );
      }
      return dateSoggiornoArrayFormatted.join(" - ");
    },
    dateSoggiornoValue: {
      get() {
        return this.dateSoggiornoArray;
      },
      set(value) {
        value.sort((a, b) => {
          return moment(a).diff(moment(b), "days");
        });
        this.dateSoggiornoArray = value.slice();
      }
    },

    DateRicevutaFormatted() {
      return this.submission.receipt_date
        ? moment(this.submission.receipt_date).format("DD/MM")
        : "";
    },
    giornoSoggiorno() {
      let dif = 0;
      if (this.submission.checkin && this.submission.checkout) {
        dif = moment(this.submission.checkout).diff(
          this.submission.checkin,
          "days"
        );
      }
      return Math.abs(dif);
    }
  }
};
</script>

<style scoped lang="scss">
label {
  color: #002762;
}

.button-form {
  justify-content: space-evenly;
}

.opacity {
  opacity: 0.3;
}
</style>
