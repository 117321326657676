<template>
  <div>
    <label
      for="trimestre"
      class="font-weight-bold tourist-tax-pro-blue text-uppercase"
      >Seleziona trimestre</label
    >
    <v-select
      id="trimestre"
      label="Seleziona"
      :items="quarters"
      v-model="quarterModel"
      filled
      dense
      color="#002762"
    ></v-select>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: false,
      default: 1
    }
  },

  data() {
    return {
      quarters: [1, 2, 3, 4]
    };
  },

  computed: {
    quarterModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
