<template>
  <div>
    <label
      for="years"
      class="font-weight-bold tourist-tax-pro-blue text-uppercase"
      >Seleziona anno</label
    >
    <v-select
      id="years"
      label="Seleziona"
      :items="years"
      v-model="yearModel"
      filled
      dense
      color="#002762"
    ></v-select>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: false,
      default: new Date().getFullYear()
    }
  },

  data() {
    return {
      years: [2018, 2019, 2020, 2021, 2022, 2023, 2024]
    };
  },

  computed: {
    yearModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
