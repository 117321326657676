<template>
  <section>
    <v-row class="room-line d-block d-md-flex">
      <v-col class="azioni text-md-center d-none d-md-flex">
        <i
          class="fas fa-eye tourist-tax-pro-blue mr-3"
          @click="showDetails()"
        ></i>
        <router-link
          :to="`/update/${id}/${submission.id}`"
          class="text-decoration-none"
        >
          <i class="far fa-edit mr-3 tourist-tax-pro-blue"></i>
        </router-link>
        <v-dialog v-model="deleteModal" persistent max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <i class="far fa-trash-alt" v-bind="attrs" v-on="on"></i>
          </template>
          <v-card>
            <v-card-title class="headline">
              Elimina tassa
            </v-card-title>
            <v-card-text
              >Eliminare definitivamente la tassa
              {{ submission.id }}?</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#002762" text @click="deleteModal = false">
                Annulla
              </v-btn>
              <v-btn color="#ec2b3c" text @click="deleteRoom()">
                Conferma
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col class="identificativo text-md-center">
        <p
          class="text-uppercase font-weight-bold tourist-tax-pro-blue d-sm-flex d-md-none"
        >
          Ricevuta N.
        </p>
        <span>{{ submission.receipt_number }}</span>
      </v-col>
      <v-col class="camera text-md-center">
        <p
          class="text-uppercase font-weight-bold tourist-tax-pro-blue d-sm-flex d-md-none"
        >
          Nome camera
        </p>
        <span>{{ submission.rooms[0].name }}</span>
      </v-col>
      <v-col class="data text-md-center">
        <p
          class="text-uppercase font-weight-bold tourist-tax-pro-blue d-sm-flex d-md-none"
        >
          Data
        </p>
        <span>{{ checkin }} - {{ checkout }}</span>
      </v-col>
      <v-col class="date-variabili text-md-center">
        <p
          class="text-uppercase font-weight-bold tourist-tax-pro-blue d-sm-flex d-md-none"
        >
          Date variabili
        </p>
        <span v-show="diffDateGuests()">
          <i class="fas fa-check tourist-tax-pro-blue"></i>
        </span>
        <span v-show="!diffDateGuests()">
          <i class="fas fa-times tourist-tax-pro-blue"></i>
        </span>
      </v-col>
      <v-col class="paganti text-md-center">
        <p
          class="text-uppercase font-weight-bold tourist-tax-pro-blue d-sm-flex d-md-none"
        >
          Ospiti paganti
        </p>
        <span>{{ numeroOspitiPaganti }}</span>
      </v-col>
      <v-col class="esenti text-md-center">
        <p
          class="text-uppercase font-weight-bold tourist-tax-pro-blue d-sm-flex d-md-none"
        >
          Ospiti esenti
        </p>
        <span>{{ numeroOspitiEsenti }}</span>
      </v-col>
      <v-col class="intermediario text-md-center">
        <p
          class="text-uppercase font-weight-bold tourist-tax-pro-blue d-sm-flex d-md-none"
        >
          Intermediario
        </p>
        <span>{{ broker.text }}</span>
      </v-col>
      <v-col class="totale text-md-center">
        <p
          class="text-uppercase font-weight-bold tourist-tax-pro-blue d-sm-flex d-md-none"
        >
          Tassa totale
        </p>
        <span>{{ submission.tax_total_amount }}</span>
      </v-col>
      <v-col class="azioni text-md-center d-sm-flex d-md-none">
        <p class="text-uppercase font-weight-bold tourist-tax-pro-blue">
          Azioni
        </p>
        <span>
          <i
            class="fas fa-eye tourist-tax-pro-blue mr-3"
            @click="showDetails()"
          ></i>
          <router-link
            :to="`/update/${id}/${submission.id}`"
            class="text-decoration-none"
          >
            <i class="far fa-edit mr-3 tourist-tax-pro-blue"></i>
          </router-link>
          <v-dialog v-model="deleteModal" persistent max-width="290">
            <template v-slot:activator="{ on, attrs }">
              <i class="far fa-trash-alt" v-bind="attrs" v-on="on"></i>
            </template>
            <v-card>
              <v-card-title class="headline">
                Elimina tassa
              </v-card-title>
              <v-card-text
                >Eliminare definitivamente la tassa
                {{ submission.id }}?</v-card-text
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#002762" text @click="deleteModal = false">
                  Annulla
                </v-btn>
                <v-btn color="#ec2b3c" text @click="deleteRoom()">
                  Conferma
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </span>
      </v-col>
    </v-row>
    <RoomDetails
      :show-details="roomDetails"
      :submission="submission"
      :guests="guests"
    />
  </section>
</template>

<script>
import moment from "moment";
import RoomDetails from "@/components/RoomDetails.vue";
export default {
  props: {
    id: {
      type: String
    },
    submission: {
      type: Object,
      required: true
    }
  },
  components: {
    RoomDetails
  },
  data() {
    return {
      roomDetails: false,
      deleteModal: false,
      guests: null,
      brokers: [
        {
          value: "airbnb",
          text: "Airbnb",
          airbnb: {
            collectTax: false
          }
        },
        {
          value: "direct",
          text: "Prenotazione diretta",
          direct: {
            collectTax: true
          }
        },
        {
          value: "booking",
          text: "Booking.com",
          booking: {
            collectTax: true
          }
        },
        {
          value: "expedia",
          text: "Expedia",
          expedia: {
            collectTax: true
          }
        },
        {
          value: "trivago",
          text: "Trivago",
          trivago: {
            collectTax: true
          }
        },
        {
          value: "homeway",
          text: "Homeway",
          homeway: {
            collectTax: true
          }
        },
        {
          value: "other",
          text: "Altro",
          other: {
            collectTax: true
          }
        }
      ]
    };
  },
  watch: {
    submission() {
      this.getGuests();
    }
  },
  created() {
    this.getGuests();
    this.diffDateSubmission();
    this.diffDateGuests();
  },
  computed: {
    checkin() {
      return moment(this.submission.checkin).format("DD/MM");
    },
    checkout() {
      return moment(this.submission.checkout).format("DD/MM");
    },
    numeroOspitiPaganti() {
      return this.guests.filter(guest => !guest.exemption).length;
    },
    numeroOspitiEsenti() {
      return this.guests.filter(guest => guest.exemption).length;
    },
    broker() {
      return this.brokers.find(
        broker => broker.value === this.submission.broker
      );
    }
  },
  methods: {
    showDetails() {
      this.roomDetails = !this.roomDetails;
    },
    async deleteRoom() {
      try {
        const result = await this.$axios.delete(
          `/api/v1/facilities/${this.id}/submissions/${this.submission.id}`
        );
        this.deleteModal = false;
        this.$emit("delete_room");
        return result;
      } catch (err) {
        return err;
      }
    },
    getGuests() {
      const guests = [];
      for (let i = 0; i < this.submission.rooms.length; i++) {
        const room = this.submission.rooms[i];

        for (let j = 0; j < room.guests.length; j++) {
          const guest = room.guests[j];
          guests.push(guest);
        }
      }
      this.guests = guests;
    },
    diffDateSubmission() {
      let dif = 0;
      if (this.submission.checkin && this.submission.checkout) {
        dif = moment(this.submission.checkout).diff(
          this.submission.checkin,
          "days"
        );
      }
      return Math.abs(dif);
    },
    diffDateGuests() {
      for (const room of this.submission.rooms) {
        for (const guest of room.guests) {
          const guestDates = Math.abs(
            moment(guest.checkout).diff(guest.checkin, "days")
          );
          if (this.diffDateSubmission() - guestDates > 0) {
            return true;
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.room-line {
  border-top: 1px solid rgba(0, 0, 0, 0.2);

  .azioni {
    width: 12%;
    max-width: 12%;
    flex-basis: 12%;
    .fa-eye,
    .fa-edit,
    .fa-trash-alt {
      font-size: 22px;
      cursor: pointer;
    }

    .fa-edit,
    .fa-trash-alt {
      &:hover {
        color: #ec2b3c;
      }
    }
  }

  .identificativo {
    width: 16%;
    max-width: 16%;
    flex-basis: 16%;
  }
  .camera {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .data {
    width: 12%;
    max-width: 12%;
    flex-basis: 12%;
  }

  .date-variabili {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .paganti {
    width: 9%;
    max-width: 9%;
    flex-basis: 9%;
  }
  .esenti {
    width: 8%;
    max-width: 8%;
    flex-basis: 8%;
  }
  .intermediario {
    width: 14%;
    max-width: 14%;
    flex-basis: 14%;
  }
  .totale {
    width: 9%;
    max-width: 9%;
    flex-basis: 9%;
  }
}

@media screen and (max-width: 960px) {
  .room-line {
    .azioni,
    .identificativo,
    .camera,
    .data,
    .date-variabili,
    .paganti,
    .esenti,
    .intermediario,
    .totale {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
  }
}

.v-application p {
  margin-bottom: 0;
}
</style>
