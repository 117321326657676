<template>
  <v-form ref="form" v-model="valid">
    <v-layout text-center wrap d-flex justify-center>
      <v-flex xs10 pb-5 class="title-container">
        <h1 class="display-2 font-weight-bold">Reserved area</h1>
      </v-flex>

      <v-flex xs10 pt-10 pb-5>
        <h2 class="sub-title font-weight-bold">
          Access to publisher-reserved area
        </h2>
      </v-flex>

      <v-flex pt-10 xs6>
        <v-text-field
          v-model="email"
          label="Email"
          filled
          required
          color="#002762"
        ></v-text-field>

        <v-text-field
          autocomplete="current-password"
          :value="userPassword"
          label="Password"
          filled
          color="#002762"
          :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="() => (value = !value)"
          :type="value ? 'password' : 'text'"
          @input="_ => (userPassword = _)"
        ></v-text-field>

        <CtaButton @click="login">Sign in</CtaButton>

        <v-flex class="mt-10">
          <router-link
            to="/forgot-password"
            class="text-uppercase forgot-password body-2 text-decoration-none"
            >password forgotten?</router-link
          >
        </v-flex>
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import CtaButton from "@/components/CtaButton.vue";
export default {
  components: {
    CtaButton
  },
  data: () => ({
    changeBackgroundColor: false,
    email: "",
    userPassword: "",
    valid: true,
    value: true,
    rules: {
      required: value => !!value || "Required."
    }
  }),
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    }
  }
};
</script>

<style lang="scss" scoped>
.title-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  h1 {
    color: #002762;
  }
}

.sub-title {
  color: #ec2b3c;
}

.forgot-password {
  color: #333;
  opacity: 0.6;
  transition: 0.3s ease;

  &:hover {
    opacity: 1;
  }
}
</style>
