<template>
  <section>
    <v-row>
      <v-col cols="12" md="6" v-if="!edit">
        <UserSelect v-model="structure.auth0_id" />
      </v-col>

      <v-col cols="12" md="6" v-if="!edit"></v-col>

      <v-col cols="12" md="6">
        <label for="name" class="font-weight-bold">Nome struttura *</label>
        <v-text-field
          required
          v-model="structure.name"
          label="Inserisci"
          filled
          dense
          color="#002762"
          id="name"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <label for="social-reason" class="font-weight-bold"
          >Ragione sociale o Soggetto responsabile</label
        >
        <v-text-field
          label="Inserisci"
          required
          v-model="structure.company_name"
          filled
          dense
          color="#002762"
          id="social-reason"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <label for="sede" class="font-weight-bold">Sede legale *</label>
        <v-text-field
          required
          v-model="structure.registered_office"
          label="Inserisci"
          filled
          dense
          color="#002762"
          id="sede"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <label for="city" class="font-weight-bold">Citta' *</label>
        <v-text-field
          label="Inserisci"
          v-model="structure.address.city"
          required
          filled
          dense
          color="#002762"
          id="city"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <label for="country" class="font-weight-bold">Paese *</label>
        <v-select
          label="Seleziona"
          :items="countries"
          v-model="structure.address.country"
          filled
          dense
          id="country"
          color="#002762"
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <label for="cap" class="font-weight-bold">CAP *</label>
        <v-text-field
          label="Inserisci"
          v-model="structure.address.postcode"
          required
          filled
          dense
          color="#002762"
          id="cap"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <label for="province" class="font-weight-bold">Provincia *</label>
        <v-select
          label="Seleziona"
          :items="province"
          v-model="structure.address.province"
          filled
          dense
          id="province"
          color="#002762"
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <label for="street" class="font-weight-bold">Indirizzo *</label>
        <v-text-field
          label="Inserisci"
          v-model="structure.address.street"
          required
          filled
          dense
          color="#002762"
          id="street"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <label for="rooms" class="font-weight-bold"
          >Numero camere disponibili *</label
        >
        <v-text-field
          required
          filled
          label="Inserisci"
          v-model="structure.rooms_count"
          type="number"
          dense
          color="#002762"
          id="rooms"
        ></v-text-field>
        <span class="description"
          >Il valore dev'essere lo stesso indicato in Ross100 -> anagrafica ->
          gestione strutture</span
        >
      </v-col>

      <v-col cols="12" md="6">
        <label for="beds" class="font-weight-bold"
          >Numero letti disponibili *</label
        >
        <v-text-field
          required
          filled
          label="Inserisci"
          v-model="structure.beds_count"
          type="number"
          dense
          color="#002762"
          id="beds"
        ></v-text-field>
        <span class="description"
          >Il valore dev'essere lo stesso indicato in Ross100 -> anagrafica ->
          gestione strutture</span
        >
      </v-col>

      <v-col cols="12" md="6">
        <label for="category" class="font-weight-bold">Tipologia</label>
        <v-text-field
          required
          filled
          label="Inserisci"
          v-model="structure.type"
          dense
          color="#002762"
          id="category"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <label for="iva" class="font-weight-bold"
          >Partita Iva o Codice fiscale</label
        >
        <v-text-field
          required
          filled
          label="Inserisci"
          v-model="structure.vat_number"
          dense
          color="#002762"
          id="iva"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-checkbox
          color="#002762"
          v-model="structure.subject_to_vat"
          :label="`Struttura soggetta a IVA`"
        ></v-checkbox>
      </v-col>

      <v-col cols="12" md="6"></v-col>

      <v-col cols="12" md="6">
        <v-checkbox
          color="#002762"
          v-model="receiveSmsNotificationsModel"
          :label="`Abilita notifiche SMS`"
        ></v-checkbox>
      </v-col>

      <v-col cols="12" md="6">
        <label for="iva" class="font-weight-bold"
          >Numero di telefono per notifiche SMS</label
        >
        <v-text-field
          required
          filled
          label="Inserisci"
          v-model="structure.sms_phone_number"
          dense
          color="#002762"
          id="sms_phone_number"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <span class="font-weight-bold tourist-tax-pro-blue"
          >* Campi obbligatori</span
        >
      </v-col>
    </v-row>
    <v-row class="button-form pt-10 pb-10 ml-5 mr-5">
      <v-col class="d-flex justify-space-between col-10 col-md-4">
        <CtaButton @click.native="cancel()">annulla</CtaButton>
        <CtaButton @click.native="edit ? update() : create()">salva</CtaButton>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import countries from "../plugins/countries.json";
import province from "../plugins/province.json";
import CtaButton from "@/components/CtaButton.vue";
import UserSelect from "@/components/UserSelect.vue";

export default {
  props: {
    edit: {
      type: Boolean,
      default: true
    }
  },

  components: {
    CtaButton,
    UserSelect
  },
  data() {
    return {
      countries: countries,
      province: province,
      structure: {
        address: {
          city: "",
          country: "",
          postcode: "",
          province: "",
          street: ""
        },
        beds_count: 0,
        company_name: "",
        name: "",
        registered_office: "",
        rooms_count: 0,
        subject_to_vat: false,
        type: "",
        vat_number: "",
        auth0_id: "",
        receive_sms_notifications: undefined,
        sms_phone_number: ""
      }
    };
  },
  computed: {
    receiveSmsNotificationsModel: {
      get() {
        return this.structure.receive_sms_notifications || undefined;
      },
      set(value) {
        this.structure.receive_sms_notifications = value || undefined;
      }
    }
  },
  async created() {
    if (this.edit) {
      this.getDataStructure();
    }
  },
  methods: {
    async getDataStructure() {
      try {
        const result = await this.$axios.get(
          `/api/v1/facilities/${this.$route.params.facilityId}`
        );
        this.structure = result.data.result;
      } catch (err) {
        this.structure = null;
      }

      if (!this.structure) {
        this.$router.push("/not-found");
      }

      this.structure.receive_sms_notifications =
        this.structure.receive_sms_notifications || undefined;
    },
    async update() {
      this.$root.alert("Salvataggio in corso...", "progress");

      try {
        const result = await this.$axios.put(
          `/api/v1/facilities/${this.$route.params.facilityId}`,
          this.structure
        );

        this.$root.alert("Struttura aggiornata correttamente");

        return result;
      } catch (err) {
        return err;
      }
    },
    async create() {
      this.$root.alert("Creazione in corso...", "progress");

      try {
        const result = await this.$axios.post(
          `/api/v1/facilities/admin`,
          this.structure
        );

        this.$root.alert("Struttura creata correttamente");
        this.$router.push("/");
        return result;
      } catch (err) {
        return err;
      }
    },
    cancel() {
      window.location.reload(true);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-input--checkbox {
  height: 30px !important;
}

label {
  color: #002762;
}

.description {
  opacity: 0.5;
}
</style>
