<template>
  <v-row class="dati-occupanti">
    <v-col cols="12" class="d-block d-sm-flex justify-space-between">
      <h1 class="tourist-tax-pro-blue font-weight-bold title">
        Dati Occupanti
      </h1>
      <v-btn
        elevation="2"
        class="white--text add-room text-uppercase"
        @click="addRoom()"
        >aggiungi camera</v-btn
      >
    </v-col>
    <v-col cols="12" v-for="(room, n) in value" :key="room.id">
      <h2 class="font-weight-bold tourist-tax-pro-blue">Camera {{ n + 1 }}</h2>
      <div class="dati-container">
        <DataRoomRow @setName="room.name = $event" :room="room" />

        <v-row class="table-h tourist-tax-pro-blue">
          <v-col class="custom14cols d-none d-md-flex"></v-col>
          <v-col class="custom19cols d-none d-md-flex">
            <h3>Nazionalita'</h3>
          </v-col>
          <v-col class="custom19cols d-none d-md-flex">
            <h3>Regione</h3>
          </v-col>
          <v-col class="custom19cols d-none d-md-flex">
            <h3>Esenzione</h3>
          </v-col>
          <v-col class="custom19cols d-none d-md-flex">
            <h3>Permanenza</h3>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="ml-2" color="red"
                  >mdi-information-outline</v-icon
                >
              </template>
              <span
                >Questo campo va compilato soltanto se le date di check in e
                di<br />
                check out del singolo ospite sono diverse dalle date generali<br />
                inserite nel campo “date di check-in e di check-out"</span
              >
            </v-tooltip>
          </v-col>
        </v-row>

        <div v-for="(guest, i) in room.guests" :key="guest.id">
          <GuestRow
            :guest="guest"
            :guestIndex="i"
            @set-guest="updateGuest(n, i, $event)"
            @delete_guest="
              $emit('delete-guest', { roomIndex: n, guestIndex: i })
            "
            :checkin="checkin"
            :checkout="checkout"
          />
        </div>

        <v-row>
          <v-col class="custom14cols d-none d-md-flex"></v-col>
          <v-col class="col-12 col-md-6">
            <v-btn
              @click.native="addGuest(n)"
              class="custom-btn-height"
              color="#002762"
            >
              <v-icon color="white">fas fa-plus</v-icon>
            </v-btn>
            <span class="ml-4 tourist-tax-pro-blue">Aggiungi ospite</span>
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col cols="12" class="text-right">
          <span
            @click="deleteRoom(n)"
            class="tourist-tax-pro-red font-weight-bold text-uppercase delete"
          >
            elimina camera<v-icon color="#ec2b3c" class="ml-2"
              >far fa-trash-alt</v-icon
            >
          </span>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import DataRoomRow from "@/components/DataRoomRow.vue";
import GuestRow from "@/components/GuestRow.vue";
export default {
  props: {
    value: {
      type: Array,
      required: true
    },
    checkin: {
      type: String,
      default: ""
    },
    checkout: {
      type: String,
      default: ""
    }
    // dateSoggiorno: {
    //   type: Array,
    //   required: true
    // }
  },
  components: {
    GuestRow,
    DataRoomRow
  },
  methods: {
    addRoom() {
      this.$emit("add-room");
    },
    addGuest(index) {
      this.$emit("add-guest", index);
    },
    deleteRoom(index) {
      this.$emit("delete-room", index);
    },
    updateGuest(roomIndex, guestIndex, guestData) {
      this.$emit("update-guest", { roomIndex, guestIndex, guestData });
    }
  }
};
</script>

<style lang="scss" scoped>
.dati-occupanti {
  border-top: 4px solid rgba(0, 0, 0, 0.2);
  border-bottom: 4px solid rgba(0, 0, 0, 0.2);
  padding: 30px 0;

  .add-room {
    background-color: #002762 !important;
  }

  .delete {
    cursor: pointer;
  }

  .dati-container {
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 20px;

    .table-h {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      .custom19cols {
        width: 19%;
        max-width: 19%;
        flex-basis: 19%;
        padding-bottom: 0;
      }

      .custom4cols {
        width: 4%;
        max-width: 4%;
        flex-basis: 4%;
        padding-bottom: 0;
      }
    }
  }
}

.custom14cols {
  width: 14%;
  max-width: 14%;
  flex-basis: 14%;
  padding-bottom: 0;
}

.title {
  padding-bottom: 10px;
}
</style>
