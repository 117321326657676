<template>
  <section class="ttp-container">
    <v-container class="ttp d-flex">
      <!-- <v-row class="nav ml-2 mb-10 mt-10 mr-0">
        <LogoutButton />
      </v-row> -->

      <div class="nav mb-10 mt-10 d-flex pl-3">
        <LogoutButton />
      </div>
      <TouristTaxPro />
    </v-container>
  </section>
</template>

<script>
import TouristTaxPro from "@/components/TouristTaxPro.vue";
import LogoutButton from "@/components/LogoutButton.vue";

export default {
  components: {
    TouristTaxPro,
    LogoutButton
  }
};
</script>

<style lang="scss" scoped>
.ttp-container {
  .ttp {
    width: 100%;
    height: 100%;
    flex-direction: column;

    .nav {
      align-self: flex-end;
    }
  }
}

@media screen and (min-width: 1900px) {
  .container {
    max-width: 1024px;
  }
}

@media screen and (min-width: 960px) {
  .ttp-container {
    width: 100vw;
    height: 100vh;
    .ttp {
      .nav {
        height: 25%;
      }
    }
  }
}
</style>
