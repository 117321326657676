<template>
  <section class="forgot-password-form-container">
    <div class="forgot-password-form d-flex justify-center align-center">
      <ForgotPasswordForm />
    </div>
  </section>
</template>

<script>
import ForgotPasswordForm from "@/components/ForgotPasswordForm.vue";
export default {
  components: {
    ForgotPasswordForm
  }
};
</script>

<style lang="scss" scoped>
.forgot-password-form-container {
  width: 100vw;
  height: 100vh;

  .forgot-password-form {
    width: 100%;
    height: 100%;
  }
}
</style>
