<template>
  <v-row>
    <v-col class="custom14cols">
      <span>Ospite {{ guestIndex + 1 }}</span>
    </v-col>
    <v-col class="custom19cols">
      <label
        for="nation"
        class="font-weight-bold tourist-tax-pro-blue d-sm-flex d-md-none"
        >Nazionalita'</label
      >
      <vue-select
        v-model="countryModel"
        :options="nationalities"
        :clearable="false"
        :searchable="true"
        :reduce="country => country.value"
        id="nation"
        placeholder="Seleziona"
        label="text"
        class="style-chooser"
      >
      </vue-select>
    </v-col>
    <v-col class="custom19cols">
      <label
        for="region"
        class="font-weight-bold tourist-tax-pro-blue d-sm-flex d-md-none"
        >Regione</label
      >
      <v-select
        label="Seleziona"
        :items="regions"
        :disabled="showRegions"
        v-model="regionModel"
        filled
        id="region"
        dense
        color="#002762"
      ></v-select>
    </v-col>
    <v-col class="custom19cols">
      <label
        for="exemptions"
        class="font-weight-bold tourist-tax-pro-blue d-sm-flex d-md-none"
        >Esenzione</label
      >
      <v-select
        label="Seleziona"
        :items="exemptions"
        v-model="exemptionModel"
        filled
        dense
        id="exemptions"
        color="#002762"
      ></v-select>
    </v-col>
    <v-col class="custom19cols">
      <v-menu
        v-model="menuDatePermanenza"
        ref="menuDatePermanenza"
        :close-on-content-click="false"
        :return-value.sync="datePermanenzaArray"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <label
            for="date"
            class="font-weight-bold tourist-tax-pro-blue d-sm-flex d-md-none"
            >Permanenza</label
          >
          <v-text-field
            v-model="datePermanenzaFormatted"
            label="Seleziona Date"
            color="#002762"
            :rules="[v => !!v || 'Campo obbligatorio']"
            :value="datePermanenzaArray"
            readonly
            required
            filled
            id="date"
            dense
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="datePermanenzaValue"
          elevation="10"
          :allowed-dates="allowedDates"
          :title-date-format="titlePermanenzaFormatted"
          locale="it"
          color="#002762"
          range
        >
          <v-spacer></v-spacer>
          <v-btn text color="#ec2b3c" @click="menuDatePermanenza = false">
            Cancella
          </v-btn>
          <v-btn
            text
            color="#002762"
            @click="$refs.menuDatePermanenza.save(datePermanenzaArray)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col class="custom4cols">
      <v-btn
        @click.native="deleteGuest()"
        color="red"
        class="custom-btn-height"
      >
        <v-icon color="white">far fa-trash-alt</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
// import countries from "../plugins/countries.json";
import region from "../plugins/regions.json";
const countries = require("i18n-iso-countries");

export default {
  props: {
    guest: {
      type: Object,
      required: true
    },
    guestIndex: {
      type: Number,
      default: 1
    },
    checkin: {
      type: String,
      default: ""
    },
    checkout: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      nationalities: [],
      regions: region,
      exemptions: [
        { value: "", text: "Nessuna esenzione" },
        { value: "underage", text: "Minori < 18 anni" },
        { value: "sick", text: "Malati per terapie" },
        { value: "sick_companion", text: "Accomp. per terapie (max 1)" },
        { value: "inpatient_assistant", text: "Assistente degente (max 1)" },
        { value: "underage_assistant", text: "Assistente minori (max 2)" },
        { value: "officer", text: "Polizia/VVFF" },
        { value: "tourist_guide", text: "Guide turistiche" },
        { value: "group_leader", text: "Acc. gruppi" },
        { value: "disabled", text: "Disabili ex L. n°104/1992" },
        { value: "disabled_companion", text: "Acc. disabili" },
        { value: "above_max_days", text: "Pernottamenti > 5 giorni" },
        { value: "resident", text: "Residente nel comune di Bergamo" }
      ],
      menuDatePermanenza: false,
      datePermanenzaArray: [],
      search: ""
    };
  },
  watch: {
    datePermanenzaArray: {
      // guarda il contenuto dell'array e lo stato intero dell'array
      deep: true,
      handler() {
        this.$emit("set-guest", {
          checkin: this.datePermanenzaArray[0],
          checkout: this.datePermanenzaArray[1]
        });
      }
    }
  },
  created() {
    countries.registerLocale(require(`i18n-iso-countries/langs/it.json`));
    const listCountries = countries.getNames("it", { select: "official" });

    const countriesArray = Object.entries(listCountries)
      .map(([value, text]) => ({
        value: value,
        text: text
      }))
      .sort(function compareFn(a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });

    this.nationalities = countriesArray;

    if (this.guest.checkin && this.guest.checkout) {
      this.datePermanenzaArray = [
        moment(this.guest.checkin).format("YYYY-MM-DD"),
        moment(this.guest.checkout).format("YYYY-MM-DD")
      ];
    }
  },
  computed: {
    datePermanenzaFormatted() {
      const datePermanenzaArrayFormatted = [];
      for (let i = 0; i < this.datePermanenzaArray.length; i++) {
        datePermanenzaArrayFormatted.push(
          moment(this.datePermanenzaArray[i]).format("DD/MM")
        );
      }
      return datePermanenzaArrayFormatted.join(" - ");
    },

    datePermanenzaValue: {
      get() {
        return this.datePermanenzaArray;
      },
      set(value) {
        value.sort((a, b) => {
          return moment(a).diff(moment(b), "days");
        });
        this.datePermanenzaArray = value.slice();
      }
    },

    countryModel: {
      get() {
        return this.guest.country;
      },
      set(value) {
        this.$emit("set-guest", { country: value });
      }
    },
    regionModel: {
      get() {
        return this.guest.region;
      },
      set(value) {
        this.$emit("set-guest", { region: value });
      }
    },
    exemptionModel: {
      get() {
        return this.guest.exemption;
      },
      set(value) {
        this.$emit("set-guest", { exemption: value });
      }
    },
    showRegions() {
      if (this.guest.country != "IT") {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    deleteGuest() {
      this.$emit("delete_guest");
    },
    getAllowedDates() {
      const arr = [];
      let startDate = moment(this.checkin);
      let endDate = moment(this.checkout);

      while (startDate <= endDate) {
        arr.push(moment(startDate).format("YYYY-MM-DD"));
        startDate = moment(startDate).add(1, "days");
      }

      return arr;
    },
    allowedDates(val) {
      for (var i = 0; i < this.getAllowedDates().length; i++) {
        if (this.getAllowedDates()[i] == val) {
          return val;
        }
      }
    },
    titlePermanenzaFormatted() {
      let datePermanenzaArrayFormatted = [];
      for (let i = 0; i < this.datePermanenzaArray.length; i++) {
        datePermanenzaArrayFormatted.push(
          moment(this.datePermanenzaArray[i]).format("DD/MM")
        );
      }
      return datePermanenzaArrayFormatted.join(" - ");
    }
  }
};
</script>

<style lang="scss" scoped>
.custom14cols {
  width: 14%;
  max-width: 14%;
  flex-basis: 14%;
  padding-bottom: 0;
}

.custom19cols {
  width: 19%;
  max-width: 19%;
  flex-basis: 19%;
  padding-bottom: 0;
}

.custom4cols {
  width: 4%;
  max-width: 4%;
  flex-basis: 4%;
  padding-bottom: 0;
}
.custom-btn-height {
  height: 52px !important;
}

@media screen and (max-width: 960px) {
  .custom14cols,
  .custom19cols,
  .custom4cols {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}

::v-deep ul.vs__dropdown-menu {
  // padding-top: 0;
  padding-left: 0;
}

::v-deep li.vs__dropdown-option {
  font-size: 0.8125rem;
  min-height: 40px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  font-weight: 500;

  &.vs__dropdown-option--highlight {
    background-color: #f6f6f6;
    color: inherit;
  }

  &.loader {
    justify-content: center;
    color: #949494;
  }
}

::v-deep div.vs__dropdown-toggle {
  border: 0;
  background-color: #f0f0f0;
  border-bottom: 1px solid #949494;
  border-radius: 3px 3px 0 0;
  padding: 12px 4px;
  margin-bottom: 21px;
}

::v-deep input.vs__search::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #606060;
}
::v-deep input.vs__search::-moz-placeholder {
  /* Firefox 19+ */
  color: #606060;
}
::v-deep input.vs__search:-ms-input-placeholder {
  /* IE 10+ */
  color: #606060;
}
::v-deep input.vs__search:-moz-placeholder {
  /* Firefox 18- */
  color: #606060;
}

::v-deep .vs__dropdown-toggle {
  padding: 12px !important;
}

::v-deep .vs__selected-options {
  flex-wrap: nowrap;
  padding: 0 !important;
}

::v-deep .vs__search {
  padding: 0;
}
::v-deep .vs__search:focus {
  padding: 0;
}

::v-deep .vs__selected {
  padding: 0;
  margin-left: 0;
}
</style>
