<template>
  <v-form>
    <v-layout text-center wrap d-flex justify-center>
      <v-flex pb-5 class="title-container">
        <h1 class="display-2 font-weight-bold">
          Tourist Tax Pro
          <a
            href="/TTP_Manuale_2021.pdf"
            target="_blank"
            rel="noopener noreferrer"
            class="text-decoration-none"
          >
            <v-icon color="#002762" class="fas fa-info-circle"></v-icon>
          </a>
        </h1>
      </v-flex>

      <v-flex pt-10 pb-5>
        <p subtitle-1 class="alert">
          <span>
            ATTENZIONE: Si informano i gestori di strutture ricettive che come
            comunicato dal Comune di Bergamo, servizio Tributi. E’ necessario
            trasmettere entro il 15 ottobre 2024, il modello 21 relativo alle
            annualità 2021, 2022 e 2023 con le modalità specificate nella
            lettera
            <a href="/Lettera_di_rendiconto_mod21_luglio2024.pdf"
              >qui allegata</a
            >. <br />Per scaricare i modelli 21 relativi alle diverse annualità
            è necessario selezionare nel campo seleziona anno, l'anno relativo
            al modello 21 che si vuole scaricare e poi schiacciare il bottone
            sottostante "Rendicontazione annuale - MOD.21".
          </span>
        </p>

        <p subtitle-1 class="sub-title text-subtitle-1">
          Registra i dati dei tuoi ospiti, trasmetti i flussi turistici a
          Ross1000 e la rendicontazione dell'imposta di soggiorno al Comune di
          Bergamo.
        </p>
      </v-flex>

      <v-flex pt-10 pb-5 d-flex class="cta-ttp">
        <div>
          <router-link to="/insert" class="text-decoration-none">
            <CtaButton class="mb-5"
              >inserisci
              <v-icon class="ml-5">fas fa-plus</v-icon>
            </CtaButton>
          </router-link>
        </div>
        <div>
          <router-link to="/manage-export" class="text-decoration-none">
            <CtaButton class="mb-5"
              >gestisci/esporta<v-icon class="ml-5"
                >fas fa-list</v-icon
              ></CtaButton
            >
          </router-link>
        </div>
        <div>
          <router-link to="/show" class="text-decoration-none">
            <CtaButton>Inserisci/modifica le tue informazioni </CtaButton>
          </router-link>
        </div>
        <div class="mt-5" v-if="admin()">
          <router-link to="/create" class="text-decoration-none">
            <CtaButton>Crea una nuova struttura </CtaButton>
          </router-link>
        </div>
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import CtaButton from "@/components/CtaButton.vue";
export default {
  components: {
    CtaButton
  },
  methods: {
    admin() {
      return this.$auth.user["https://www.visitbergamo.net"].roles.includes(
        "ttp_admin"
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.title-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  h1 {
    color: #002762;
  }
}

.alert {
  background-color: #fff9c4;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 1.2em;
  display: flex;
  text-align: left;
  justify-content: center;
  align-items: center;
  color: black;
  border-radius: 4px;
}

.sub-title {
  color: #333;
}

.cta-ttp {
  flex-direction: column;
}
</style>
