<template>
  <section class="mb-10">
    <v-row>
      <v-col cols="12" md="6">
        <FacilitySelect v-model="facilityId" />
      </v-col>

      <v-col cols="12" md="6">
        <PeriodSelect
          :month="currentMonth"
          :year="currentYear"
          @select="setPeriod"
        />
      </v-col>
    </v-row>

    <SubmissionsList
      :facility-id="facilityId"
      :start-date="startDate"
      :end-date="endDate"
    />

    <ManageClosingDays
      :facility-id="facilityId"
      :start-date="startDate"
      :end-date="endDate"
    />
  </section>
</template>

<script>
import moment from "moment";

import FacilitySelect from "@/components/FacilitySelect.vue";
import PeriodSelect from "@/components/PeriodSelect.vue";
import ManageClosingDays from "@/components/ManageClosingDays.vue";
import SubmissionsList from "@/components/SubmissionsList.vue";

export default {
  components: {
    FacilitySelect,
    PeriodSelect,
    ManageClosingDays,
    SubmissionsList
  },
  data() {
    return {
      facilityId: this.$route.query.facility || "",
      startDate: moment([
        Number(this.$route.query.year) || moment().year(),
        Number(this.$route.query.month) || moment().month(),
        1
      ])
        .startOf("month")
        .format("YYYY-MM-DD"),
      endDate: moment([
        Number(this.$route.query.year) || moment().year(),
        Number(this.$route.query.month) || moment().month(),
        1
      ])
        .endOf("month")
        .format("YYYY-MM-DD")
    };
  },

  computed: {
    currentMonth() {
      return Number(this.$route.query.month) || moment().month();
    },
    currentYear() {
      return Number(this.$route.query.year) || moment().year();
    }
  },

  methods: {
    setPeriod({ startDate, endDate }) {
      this.startDate = startDate;
      this.endDate = endDate;
    }
  }
};
</script>
