<template>
  <section>
    <v-container>
      <v-row class="mb-10 mt-10 d-flex pl-3 pr-3 justify-space-between">
        <router-link to="/" class="text-decoration-none">
          <CtaButton>Indietro</CtaButton>
        </router-link>
        <LogoutButton />
      </v-row>

      <v-row>
        <v-col pb-10 class="title-container col-12">
          <h1 class="display-1 font-weight-bold text-uppercase">
            <v-icon class="list-icon">fas fa-list</v-icon> gestisci/esporta
          </h1>
        </v-col>

        <v-col pt-5 pb-5 class="col-12">
          <h2 class="text-uppercase sub-title font-weight-bold pb-4">
            visualizza e modifica i dati dei pernottamenti
          </h2>
          <h3 subtitle-1 class="text-subtitle-1">
            Inserisci i tuoi check-in, calcola automaticamente l'importo
            dell'imposta di soggiorno, registra i dati per la rendicontazione al
            Comune di Bergamo e inserisci i dati relativi ai flussi turistici da
            inviare a Ross1000.
          </h3>
        </v-col>
      </v-row>

      <ManageExportSelect />

      <v-row>
        <v-col pt-5 pb-5 class="col-12">
          <h2 class="text-uppercase sub-title font-weight-bold pb-4">
            invia i flussi turistici a Ross1000 e la rendicontazione
            dell'imposta di soggiorno al comune di Bergamo
          </h2>
          <h3 subtitle-1 class="text-subtitle-1 pb-2">
            Da questa sezione puoi inviare i dati relativi ai flussi turistici a
            Ross1000 (entro il 5 del mese) e puoi rendicontare l'imposta di
            soggiorno al comune di Bergamo (rendicontazione trimestrale).
          </h3>

          <h3 subtitle-1 class="text-subtitle-1 pb-5">
            NB: Tourist Tax Pro verifica automaticamente che i dati inseriti
            siano completi e coerenti, ti chiediamo di verificare e correggere
            eventuali errori o anomalie segnalate qui sopra.
          </h3>

          <router-link to="/insert" class="text-decoration-none">
            <CtaButton
              >INSERISCI NUOVO CHECKIN
              <v-icon class="ml-5">fas fa-plus</v-icon>
            </CtaButton>
          </router-link>
        </v-col>
      </v-row>
      <v-row class="my-5">
        <Turismo5Select />
      </v-row>
    </v-container>
  </section>
</template>

<script>
import LogoutButton from "@/components/LogoutButton.vue";
import CtaButton from "@/components/CtaButton.vue";
import Turismo5Select from "@/components/Turismo5Select.vue";
import ManageExportSelect from "@/components/ManageExportSelect.vue";

export default {
  components: {
    CtaButton,
    LogoutButton,
    Turismo5Select,
    ManageExportSelect
  }
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1900px) {
  .container {
    max-width: 1040px;
  }
}

.title-container {
  h1 {
    color: #002762;

    .list-icon {
      color: #002762;
    }
  }
}

.sub-title {
  color: #002762;
}
</style>
