import Vue from "vue";
import VueRouter from "vue-router";
import Homepage from "../views/Homepage.vue";
import Login from "../views/Login.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import Insert from "../views/Insert.vue";
import ManageExport from "../views/ManageExport.vue";
import Update from "../views/Update.vue";
import EditPdf from "../views/EditPdf.vue";
import Show from "../views/Show.vue";
import Edit from "../views/EditInformations.vue";
import Create from "../views/CreateFacility.vue";
import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Homepage",
    component: Homepage,
    beforeEnter: authGuard
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: ForgotPassword
  },
  {
    path: "/insert",
    name: "Insert",
    component: Insert,
    beforeEnter: authGuard
  },
  {
    path: "/manage-export",
    name: "ManageExport",
    component: ManageExport,
    beforeEnter: authGuard
  },
  {
    path: "/update/:facilityId/:submissionId",
    name: "Update",
    component: Update,
    beforeEnter: authGuard
  },
  {
    path: "/edit-pdf/:facilityId",
    name: "EditPdf",
    component: EditPdf,
    beforeEnter: authGuard
  },
  {
    path: "/show",
    name: "Show",
    component: Show,
    beforeEnter: authGuard
  },
  {
    path: "/edit/:facilityId",
    name: "Edit",
    component: Edit,
    beforeEnter: authGuard
  },
  {
    path: "/create",
    name: "Create",
    component: Create,
    beforeEnter: authGuard
  }
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function() {
  //     return import(/* webpackChunkName: "about" */ "../views/About.vue");
  //   }
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
