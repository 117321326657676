<template>
  <v-row class="mt-6">
    <v-col>
      <v-row class="mt-6">
        <v-col class="col-12 col-md-6">
          <span style="display: block;" class="font-weight-bold text-subtitle-1"
            >Giorni chiusura mese: {{ monthTotal }}</span
          >
          <span style="display: block;" class="font-weight-bold text-subtitle-1"
            >Giorni chiusura da inizio anno: {{ total }}</span
          >
        </v-col>
        <v-col class="text-left text-md-right col-12 col-md-6">
          <v-btn
            @click="open = !open"
            elevation="2"
            class="white--text send"
            style="backgroundColor: #002762;"
            >Modifica i giorni di chiusura</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="my-5">
        <section
          v-show="open"
          class="closing-days-container px-10 pb-8 pt-3 full-width"
        >
          <v-row>
            <v-col cols="12">
              <h3 class="text-uppercase font-weight-bold tourist-tax-pro-blue">
                inserisci i giorni di chiusura della tua attivita'
              </h3>
              <p>
                Non ci risultano pernottamenti in queste giornate, seleziona le
                date di effettiva chiusura della tua struttura.
              </p>
            </v-col>
          </v-row>
          <v-row class="calendar-row mb-10">
            <div v-for="(payload, date) in calendar" :key="date">
              <v-checkbox
                :input-value="!!payload && payload.type === 'closing'"
                :disabled="!!payload && payload.type === 'stay'"
                @change="toggleCheck($event, date, payload)"
                :label="formatDay(date)"
              ></v-checkbox>
            </div>
          </v-row>
          <v-row>
            <v-col class="text-right">
              <v-btn
                elevation="2"
                color="#ec2b3c"
                class="white--text"
                @click="save"
                >Salva</v-btn
              >
            </v-col>
          </v-row>
        </section>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  props: {
    facilityId: {
      type: String,
      required: false,
      default: ""
    },
    startDate: {
      type: String,
      required: false,
      default: ""
    },
    endDate: {
      type: String,
      required: false,
      default: ""
    }
  },

  data() {
    return {
      open: false,
      monthTotal: 0,
      total: 0,
      calendar: {},
      inserts: {},
      deletes: {}
    };
  },

  watch: {
    facilityId: {
      immediate: true,
      handler() {
        this.loadCalendar();
      }
    },
    startDate: {
      immediate: true,
      handler() {
        this.loadCalendar();
      }
    },
    endDate: {
      immediate: true,
      handler() {
        this.loadCalendar();
      }
    }
  },

  methods: {
    async loadCalendar() {
      if (!this.facilityId || !this.startDate || !this.endDate) {
        return;
      }

      this.inserts = {};
      this.deletes = {};

      try {
        const { data } = await this.$axios.get(
          `/api/v1/facilities/${this.facilityId}/calendar`,
          {
            params: {
              from: this.startDate,
              to: this.endDate
            }
          }
        );

        this.total = data.result.yearCount;
        this.monthTotal = data.result.periodCount;
        this.calendar = data.result.calendar;
      } catch (err) {
        // Do nothing
      }
    },

    addInsert(date) {
      this.$delete(this.deletes, date);
      this.$set(this.inserts, date, true);
    },

    addDelete(date, id) {
      this.$delete(this.inserts, date);

      if (id) {
        this.$set(this.deletes, date, id);
      }
    },

    formatDay(date) {
      return moment(date).format("DD/MM");
    },

    toggleCheck(checked, date, payload) {
      if (checked) {
        this.addInsert(date);
      } else {
        this.addDelete(date, payload ? payload.id : "");
      }
    },

    async save() {
      const insertPromises = Object.keys(this.inserts).map(date =>
        this.$axios
          .post(`/api/v1/facilities/${this.facilityId}/closing-days/${date}`)
          .then(() => {
            this.$root.alert("Giorni di chiusura salvati correttamenti");
          })
          .catch(() => {})
      );

      const deletePromises = Object.keys(this.deletes).map(date =>
        this.$axios
          .delete(`/api/v1/facilities/${this.facilityId}/closing-days/${date}`)
          .then(() => {
            this.$root.alert("Giorni di chiusura salvati correttamenti");
          })
          .catch(() => {})
      );

      await Promise.all([...insertPromises, ...deletePromises]);

      await this.loadCalendar();
    }
  }
};
</script>

<style lang="scss" scoped>
.closing-days-container {
  background-color: #f1f1f1;
}

.full-width {
  width: 100%;
}

.v-input--checkbox {
  height: 35px;

  &.v-input--is-disabled {
    opacity: 0.3;
  }
}

.v-input--selection-controls {
  margin-top: 0 !important;
}

.calendar-row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 350px;
}
</style>
