<template>
  <v-form ref="form" v-model="valid">
    <v-layout text-center wrap d-flex justify-center>
      <v-flex xs10 pb-5 class="title-container">
        <h1 class="display-2 font-weight-bold">Forgot your password?</h1>
      </v-flex>

      <v-flex xs10 pt-10 pb-5>
        <p class="sub-title body-2">
          Please, add your email address below and click "Send".<br />
          You will be sent a link by email for access so that you can change
          your password.
        </p>
      </v-flex>

      <v-flex pt-10 xs6>
        <v-text-field
          v-model="email"
          label="Email"
          filled
          required
          color="#002762"
        ></v-text-field>

        <CtaButton>Send</CtaButton>
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import CtaButton from "@/components/CtaButton.vue";
export default {
  components: {
    CtaButton
  },
  data: () => ({
    changeBackgroundColor: false,
    email: "",
    valid: true
  })
};
</script>

<style lang="scss" scoped>
.title-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  h1 {
    color: #002762;
  }
}

.sub-title {
  color: #333;
}
</style>
