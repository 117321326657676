export const exemptions = {
  underage: {
    label: "Minori < 18 anni",
    description: "Esenti ai sensi dell'art 5, c. 1, lett. a):"
  },
  sick: {
    label: "Malati per terapie",
    description: "Esenti ai sensi dell'art 5, c. 1, lett. b):"
  },
  sick_companion: {
    label: "Accomp. per terapie (max 1)",
    description: "Esenti ai sensi dell'art 5, c. 1, lett. b):"
  },
  inpatient_assistant: {
    label: "Assistente degente (max 1)",
    description: "Esenti ai sensi dell'art 5, c. 1, lett. c):"
  },
  underage_assistant: {
    label: "Assistente minori (max 2)",
    description: "Esenti ai sensi dell'art 5, c. 1, lett. d):"
  },
  officer: {
    label: "Polizia/VVFF",
    description: "Esenti ai sensi dell'art 5, c. 1, lett. e):"
  },
  tourist_guide: {
    label: "Guide turistiche",
    description: "Esenti ai sensi dell'art 5, c. 1, lett. f):"
  },
  group_leader: {
    label: "Acc. gruppi",
    description: "Esenti ai sensi dell'art 5, c. 1, lett. g):"
  },
  disabled: {
    label: "Disabili ex L. n°104/1992",
    description: "Esenti ai sensi dell'art 5, c. 1, lett. h):"
  },
  disabled_companion: {
    label: "Acc. disabili",
    description: "Esenti ai sensi dell'art 5, c. 1, lett. h):"
  },
  above_max_days: {
    label: "Pernottamenti > 5 giorni",
    description: "(art. 4 comma 3 del Regolamento C.le)"
  },
  resident: {
    label: "Residente nel comune di Bergamo",
    description: "(art. 3 comma 1 del Regolamento C.le)"
  }
};

export const exemptionChoices = Object.keys(exemptions).map(key => {
  return {
    text: exemptions[key].label,
    value: key
  };
});
