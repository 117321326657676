import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import { domain, clientId } from "../auth_config.json";

// Import the plugin here
import { Auth0Plugin } from "./auth";

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.component("vue-select", vSelect);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,

  render: function(h) {
    return h(App);
  },

  methods: {
    alert(text, method = "success") {
      this.$children[0][method](text);
    },
    hideAlert() {
      this.$children[0].hideAlert();
    }
  }
}).$mount("#app");
