<template>
  <section>
    <v-container>
      <v-row class="row mb-10 mt-10 d-flex pl-3 pr-3 justify-space-between">
        <router-link to="/" class="text-decoration-none">
          <CtaButton>Indietro</CtaButton>
        </router-link>
        <LogoutButton />
      </v-row>
      <v-row>
        <v-flex xs12 pb-5 ml-3 mr-3 class="title-container">
          <h1 class="display-1 font-weight-bold">
            Benvenuto su Tourist Tax Pro
          </h1>
        </v-flex>

        <v-flex xs12 pt-5 pb-5 ml-3 mr-3>
          <p subtitle-1 class="sub-title text-subtitle-1">
            Inserisci i pernottamenti, calcola automaticamente l’imposta di
            soggiorno, registra i dati utili alla rendicontazione trimestrale e
            le informazioni relative ai flussi turistici da inviare a Ross1000.
          </p>
        </v-flex>

        <v-flex xs12 pb-5>
          <p
            class="pb-5 pt-5 pl-3 pr-3 font-weight-bold white--text text-uppercase mini-title"
          >
            inserimento dati pernottamenti
          </p>
        </v-flex>
      </v-row>

      <InsertForm />
    </v-container>
  </section>
</template>

<script>
import LogoutButton from "@/components/LogoutButton.vue";
import CtaButton from "@/components/CtaButton.vue";
import InsertForm from "@/components/InsertForm.vue";

export default {
  components: {
    CtaButton,
    LogoutButton,
    InsertForm
  }
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1900px) {
  .container {
    max-width: 1040px;
  }
}

.title-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  h1 {
    color: #002762;
  }
}

.mini-title {
  background-color: #002762;
}
</style>
