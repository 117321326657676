<template>
  <section>
    <v-container>
      <v-row class="row mb-10 mt-10 d-flex pl-3 pr-3 justify-space-between">
        <router-link to="/" class="text-decoration-none">
          <CtaButton>Indietro</CtaButton>
        </router-link>
        <LogoutButton />
      </v-row>
      <v-row>
        <v-flex xs12 pb-5 ml-5 mr-5 class="title-container">
          <h1
            class="display-1 font-weight-bold text-center tourist-tax-pro-blue"
          >
            Area riservata
          </h1>
        </v-flex>
      </v-row>

      <v-row>
        <v-col cols="12">
          <label
            for="structure-name"
            class="font-weight-bold tourist-tax-pro-blue"
            >Filtra per nome della struttura</label
          >
          <v-text-field
            label="Inserisci"
            v-model="search"
            filled
            dense
            color="#002762"
            id="structure-name"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="heading mb-3">
        <v-col class="subtitle-1 font-weight-bold col-md-10 col-9"
          >Nome struttura</v-col
        >
        <v-col class="subtitle-1 font-weight-bold text-center col-md-2 col-3"
          >Azioni</v-col
        >
      </v-row>
      <v-row v-for="(facility, i) in facilities" :key="i" class="border list">
        <v-col cols="10" class="font-weight-bold tourist-tax-pro-blue ">
          {{ facility.name }}
        </v-col>
        <v-col cols="2" class="text-center">
          <router-link
            :to="`/edit/${facility.id}`"
            class="text-decoration-none"
          >
            <i class="far fa-edit tourist-tax-pro-blue"></i>
          </router-link>
        </v-col>
      </v-row>
      <v-row v-if="facilities.length">
        <v-col>
          <div class="text-center">
            <v-pagination
              v-model="page"
              :length="totalPages"
              color="#002762"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import LogoutButton from "@/components/LogoutButton.vue";
import CtaButton from "@/components/CtaButton.vue";

export default {
  components: {
    CtaButton,
    LogoutButton
  },
  data() {
    return {
      facilities: [],
      page: 1,
      search: "",
      totalPages: 1
    };
  },
  async created() {
    this.getStructures();
  },
  watch: {
    search() {
      this.getStructures();
    }
  },
  methods: {
    async getStructures() {
      try {
        const { data } = await this.$axios.get(`/api/v1/facilities`, {
          params: {
            page: this.page,
            search: this.search
          }
        });

        this.facilities = data.results;
        this.totalPages = data.lastPage;
      } catch (err) {
        return err;
      }
    }
  }
};
</script>

<style lang="scss">
@media screen and (min-width: 1900px) {
  .container {
    max-width: 1040px;
  }
}

.heading {
  background-color: #002762;
  color: white;
}

.border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
</style>
