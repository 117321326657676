<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>

    <v-alert
      ref="alert"
      :color="alertColor"
      dark
      border="left"
      prominent
      :dismissible="!loading"
      v-model="alertModel"
    >
      <v-row class="align-center">
        <v-col cols="2" v-if="loading">
          <v-progress-circular
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-col>
        <v-col :cols="loading ? 10 : 12">
          <div v-html="alertText"></div>
        </v-col>
      </v-row>
    </v-alert>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      alertText: "",
      alertModel: false,
      alertColor: "#e86a20",
      alertTimeout: null
    };
  },

  methods: {
    success(text) {
      this.showAlert(text, "#55b717");
    },
    error(text) {
      this.showAlert(text, "#e86a20");
    },
    progress(text) {
      this.showAlert(text, "#4c4c4c", true);
    },
    showAlert(text, color, loading = false) {
      clearTimeout(this.alertTimeout);

      this.loading = loading;
      this.alertColor = color;
      this.alertText = text;
      this.alertModel = true;

      this.alertTimeout = setTimeout(() => {
        this.alertModel = false;
      }, 5000);
    },
    hideAlert() {
      this.alertModel = false;
    }
  }
};
</script>

<style lang="scss">
.tourist-tax-pro-red {
  color: #ec2b3c;
}

.tourist-tax-pro-blue {
  color: #002762;
}

@media screen and (min-width: 1900px) {
  .container {
    max-width: 1040px;
  }
}

.v-input__slot {
  height: 52px !important;
  min-height: 52px !important;
}
</style>
<style lang="scss" scoped>
.v-alert {
  position: fixed;
  margin: 0;
  bottom: 30px;
  right: 30px;
}
</style>
