<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="6">
          <label for="codice-struttura" class="font-weight-bold">{{
            result.label
          }}</label>
          <v-text-field
            required
            v-model="result.facility_id"
            filled
            dense
            color="#002762"
            id="codice-struttura"
          ></v-text-field>
          <span class="description">{{ result.description }}</span>
        </v-col>
      </v-row>

      <v-row class="button-form pt-10 pb-10 ml-5 mr-5">
        <v-col class="d-flex justify-space-between col-10 col-md-4">
          <CtaButton @click.native="cancel()">annulla</CtaButton>
          <CtaButton @click.native="update()">salva</CtaButton>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import CtaButton from "@/components/CtaButton.vue";
export default {
  data() {
    return {
      result: {
        label: "",
        description: "",
        facility_id: ""
      }
    };
  },
  components: {
    CtaButton
  },
  created() {
    this.fieldsIntegrations();
    this.dataIntegrations();
  },
  methods: {
    async fieldsIntegrations() {
      try {
        const { data } = await this.$axios.get(
          `/api/v1/facilities/${this.$route.params.facilityId}/integrations`
        );
        this.fields = data.result.QuarterlyMunicipalityReporting.fields;
      } catch (err) {
        return err;
      }
    },
    async dataIntegrations() {
      try {
        const { data } = await this.$axios.get(
          `/api/v1/facilities/${this.$route.params.facilityId}/integrations/QuarterlyMunicipalityReporting`
        );
        this.result.facility_id = data.result.data.facility_id;
        this.result.label = data.result.fields.facility_id.name;
        this.result.description = data.result.fields.facility_id.description;
      } catch (err) {
        return err;
      }
    },
    async update() {
      this.$root.alert("Salvataggio in corso...", "progress");
      try {
        const result = await this.$axios.put(
          `/api/v1/facilities/${this.$route.params.facilityId}/integrations/QuarterlyMunicipalityReporting`,
          this.result
        );

        this.$root.alert("Struttura aggiornata correttamente");

        return result;
      } catch (err) {
        return err;
      }
    },
    cancel() {
      window.location.reload(true);
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  color: #002762;
}

.description {
  opacity: 0.5;
}
</style>
