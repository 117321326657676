<template>
  <section class="login-form-container">
    <div class="login-form d-flex justify-center align-center">
      <LoginForm />
    </div>
  </section>
</template>

<script>
import LoginForm from "@/components/LoginForm.vue";
export default {
  components: {
    LoginForm
  }
};
</script>

<style lang="scss" scoped>
.login-form-container {
  width: 100vw;
  height: 100vh;

  .login-form {
    width: 100%;
    height: 100%;
  }
}
</style>
