<template>
  <div>
    <label class="font-weight-bold tourist-tax-pro-blue"
      >Seleziona struttura</label
    >

    <vue-select
      placeholder="Seleziona"
      v-model="facilityModel"
      :options="facilities"
      label="name"
      :clearable="false"
      :searchable="true"
      :filterable="false"
      :disabled="disabled"
      @open="onOpen"
      @close="onClose"
      @search="filterFacilities"
    >
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes" style="padding-right: 7px">
          <i
            aria-hidden="true"
            class="v-icon notranslate fas fa-caret-down theme--light"
          ></i>
        </span>
      </template>
      <template #list-footer>
        <li ref="load" class="loader vs__dropdown-option" v-show="hasMorePages">
          Caricamento...
        </li>
      </template>
    </vue-select>
  </div>
</template>

<script>
import { debounce } from "../utils";

export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ""
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      facilities: [],
      observer: new IntersectionObserver(this.infiniteScroll),
      limit: 20,
      page: 1,
      hasMorePages: false,
      scrollTop: 0,
      search: "",
      facilityModel: null
    };
  },

  watch: {
    page() {
      this.loadFacilities();
    },
    facilityModel() {
      this.$emit("input", this.facilityModel ? this.facilityModel.id : null);
    }
  },

  async created() {
    await this.loadFacilities();
    // console.log(this.value);
    if (this.value) {
      this.facilityModel = this.facilities.find(facility => {
        return facility.id === this.value;
      });

      this.facilityModel = await this.getFacility(this.value);
    }
  },

  methods: {
    async getFacility(id) {
      try {
        const { data } = await this.$axios.get(`/api/v1/facilities/${id}`);

        return data.result;
      } catch (err) {
        return null;
      }
    },

    async loadFacilities() {
      try {
        const { data } = await this.$axios.get(`/api/v1/facilities`, {
          params: {
            limit: this.limit,
            page: this.page,
            search: this.search
          }
        });

        if (this.page > 1) {
          this.facilities.push(...data.results);

          await this.$nextTick();
          if (this.$refs.load) {
            this.$refs.load.parentElement.scrollTop = this.scrollTop;
          }
        } else {
          this.facilities = data.results;
        }

        this.hasMorePages = data.lastPage > data.page;
      } catch (err) {
        // Do nothing
      }
    },

    async onOpen() {
      if (this.hasMorePages) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    async onClose() {
      await this.$nextTick();
      this.observer.disconnect();
    },

    infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        this.scrollTop = target.offsetParent.scrollTop;
        this.page++;
      }
    },

    async filterFacilities(query, loading) {
      this.search = query;
      this.page = 1;
      loading(true);
      this.debouncedSearch(this, loading);
    },

    debouncedSearch: debounce(async (vm, loading) => {
      await vm.loadFacilities();
      loading(false);
      await vm.$nextTick();
      vm.observer.observe(vm.$refs.load);
    }, 300)
  }
};
</script>

<style lang="scss">
ul.vs__dropdown-menu {
  // padding-top: 0;
  padding-left: 0;
}

li.vs__dropdown-option {
  font-size: 0.8125rem;
  min-height: 40px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  font-weight: 500;

  &.vs__dropdown-option--highlight {
    background-color: #f6f6f6;
    color: inherit;
  }

  &.loader {
    justify-content: center;
    color: #949494;
  }
}

div.vs__dropdown-toggle {
  border: 0;
  background-color: #f0f0f0;
  border-bottom: 1px solid #949494;
  border-radius: 3px 3px 0 0;
  padding: 12px 4px;
  margin-bottom: 21px;
}

input.vs__search::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #606060;
}
input.vs__search::-moz-placeholder {
  /* Firefox 19+ */
  color: #606060;
}
input.vs__search:-ms-input-placeholder {
  /* IE 10+ */
  color: #606060;
}
input.vs__search:-moz-placeholder {
  /* Firefox 18- */
  color: #606060;
}
</style>
