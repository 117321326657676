<template>
  <v-btn
    elevation="2"
    v-bind="$props"
    v-on="$listeners"
    class="white--text send"
    ><slot></slot
  ></v-btn>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.send {
  background-color: #002762 !important;
  transition: 0.3s ease-in;
  font-size: clamp(0.7rem, 1vw, 1rem);
  &:hover {
    background-color: #ec2b3c !important;
  }

  &:disabled {
    opacity: 0.3;
  }
}
</style>
