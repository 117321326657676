<template>
  <v-menu v-model="menuModel" :close-on-content-click="false" max-width="290">
    <template v-slot:activator="{ on, attrs }">
      <label for="period" class="font-weight-bold tourist-tax-pro-blue"
        >Seleziona periodo</label
      >
      <v-text-field
        :value="dateLabel"
        readonly
        id="period"
        color="#002762"
        required
        filled
        v-bind="attrs"
        v-on="on"
        append-icon="mdi-calendar"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="pickerDate"
      color="#002762"
      locale="it"
      type="month"
      @change="menuModel = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";

export default {
  props: {
    month: {
      type: Number,
      required: false,
      default: moment().month()
    },
    year: {
      type: Number,
      required: false,
      default: moment().year()
    }
  },

  data() {
    return {
      pickerDate: moment([this.year, this.month, 1]).format("YYYY-MM-DD"),
      menuModel: false
    };
  },

  watch: {
    pickerDate() {
      this.$nextTick(() => {
        this.$emit("select", {
          startDate: this.startDate,
          endDate: this.endDate
        });
      });
    }
  },

  computed: {
    dateLabel() {
      return moment(this.pickerDate)
        .locale("it")
        .format("MMMM YYYY");
    },
    startDate() {
      return moment(this.pickerDate)
        .startOf("month")
        .format("YYYY-MM-DD");
    },
    endDate() {
      return moment(this.pickerDate)
        .endOf("month")
        .format("YYYY-MM-DD");
    }
  }
};
</script>

<style lang="scss" scoped></style>
