<template>
  <v-row v-show="showDetails">
    <v-col cols="12">
      <v-row>
        <v-col class="azioni d-none d-md-block"></v-col>
        <v-col cols="4">
          <h4 class="tourist-tax-pro-blue mb-1">
            Costo totale soggiorno
          </h4>
          <span>{{ total_price }}</span>
        </v-col>
        <v-col cols="4">
          <h4 class="tourist-tax-pro-blue mb-1">
            Tassa totale soggiorno
          </h4>
          <span>{{ tax_total }}</span>
        </v-col>
        <v-col cols="2">
          <h4 class="tourist-tax-pro-blue mb-1">Ricevuta</h4>
          <span>{{ submission.receipt_number }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col class="azioni d-none d-md-block"></v-col>
        <v-col cols="4">
          <h4 class="tourist-tax-pro-blue mb-1">Provenienza Ospiti</h4>
          <p v-for="(guest, i) in guests" :key="i" class="mb-0">
            Ospite {{ i + 1 }}: {{ countryName(guest.country)
            }}{{ regionName(guest.region) }}
          </p>
        </v-col>
        <v-col cols="4">
          <h4 class="tourist-tax-pro-blue mb-1">Date pernottamenti</h4>
          <p v-for="(guest, i) in guests" :key="i" class="mb-0">
            {{ formatDate(guest.checkin) }} - {{ formatDate(guest.checkout) }}
          </p>
        </v-col>
        <v-col cols="2">
          <h4 class="tourist-tax-pro-blue mb-1">Camere occupate</h4>
          <span>{{ this.submission.rooms.length }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="azioni d-none d-md-block"></v-col>
        <v-col cols="10">
          <h4 class="tourist-tax-pro-blue mb-1">
            Identificativo
          </h4>
          <span class="text-sm">{{ submission.number }}</span>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import countries from "../plugins/countries.json";
import regions from "../plugins/regions.json";
import moment from "moment";

export default {
  props: {
    showDetails: {
      type: Boolean,
      default: false
    },
    submission: {
      type: Object,
      default: null
    },
    guests: {
      type: Array,
      default: null
    }
  },
  computed: {
    total_price() {
      const total = this.submission.rooms[0].total_price_cents / 100;
      return "€" + total.toFixed(2);
    },
    tax_total() {
      const total = this.submission.rooms[0].tax_total_amount_cents / 100;
      return "€" + total.toFixed(2);
    }
  },
  methods: {
    countryName(code) {
      const country = countries.find(country => country.value === code);
      if (country) {
        return country.text;
      }
    },
    regionName(code) {
      const region = regions.find(region => region.value === code);
      if (region) {
        return ", " + region.text;
      }
    },
    formatDate(date) {
      return moment(date).format("DD/MM");
    }
  }
};
</script>

<style scoped lang="scss">
.azioni {
  width: 12%;
  max-width: 12%;
  flex-basis: 12%;
}
</style>
