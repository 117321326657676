<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col cols="12">
          <h3 class="text-uppercase font-weight-bold tourist-tax-pro-blue mb-2">
            elenco pernottamenti periodo selezionato
          </h3>
        </v-col>
      </v-row>

      <v-row class="table" v-if="submissions.length">
        <v-col cols="12" class="no-padding">
          <v-row class="d-none d-md-flex">
            <v-col class="azioni text-center">
              <span
                class="text-uppercase font-weight-bold tourist-tax-pro-blue"
              >
                Azioni
              </span>
            </v-col>
            <v-col class="identificativo text-center">
              <span
                class="text-uppercase font-weight-bold tourist-tax-pro-blue"
              >
                Ricevuta N.
              </span>
            </v-col>
            <v-col class="camera text-center">
              <span
                class="text-uppercase font-weight-bold tourist-tax-pro-blue"
              >
                Nome<br />camera
              </span>
            </v-col>
            <v-col class="data text-center">
              <span
                class="text-uppercase font-weight-bold tourist-tax-pro-blue"
              >
                Data
              </span>
            </v-col>
            <v-col class="date-variabili text-center">
              <span
                class="text-uppercase font-weight-bold tourist-tax-pro-blue"
              >
                Date<br />variabili
              </span>
            </v-col>
            <v-col class="paganti text-center">
              <span
                class="text-uppercase font-weight-bold tourist-tax-pro-blue"
              >
                Ospiti<br />paganti
              </span>
            </v-col>
            <v-col class="esenti text-center">
              <span
                class="text-uppercase font-weight-bold tourist-tax-pro-blue"
              >
                Ospiti<br />esenti
              </span>
            </v-col>
            <v-col class="intermediario text-center">
              <span
                class="text-uppercase font-weight-bold tourist-tax-pro-blue"
              >
                Intermediario
              </span>
            </v-col>
            <v-col class="totale text-center">
              <span
                class="text-uppercase font-weight-bold tourist-tax-pro-blue"
              >
                tassa totale
              </span>
            </v-col>
          </v-row>
          <div v-for="submission in submissions" :key="submission.id">
            <RoomRow
              :id="facilityId"
              :submission="submission"
              @delete_room="onDelete"
            />
          </div>
        </v-col>
      </v-row>

      <div v-if="!facilityId || !submissions.length" class="text-center">
        <h3 class="font-weight-medium">
          Nessuna tassa registrata in questo periodo
        </h3>
      </div>

      <v-row v-if="submissions.length">
        <v-col>
          <div class="text-center">
            <v-pagination
              v-model="page"
              :length="totalPages"
              color="#002762"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import RoomRow from "@/components/RoomRow.vue";

export default {
  components: {
    RoomRow
  },

  props: {
    facilityId: {
      type: String,
      required: false,
      default: ""
    },
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      submissions: [],
      page: 1,
      totalPages: 1
    };
  },

  watch: {
    facilityId: {
      immediate: true,
      handler() {
        this.submissions = [];
        this.page = 1;
        this.getRooms();
      }
    },
    startDate: {
      immediate: true,
      handler() {
        this.page = 1;
        this.getRooms();
      }
    },
    endDate: {
      immediate: true,
      handler() {
        this.page = 1;
        this.getRooms();
      }
    },
    page() {
      this.getRooms();
    }
  },

  methods: {
    async getRooms() {
      if (!this.facilityId) {
        return;
      }

      try {
        const { data } = await this.$axios.get(
          `/api/v1/facilities/${this.facilityId}/submissions`,
          {
            params: {
              from: this.startDate,
              to: this.endDate,
              page: this.page
            }
          }
        );
        this.submissions = data.results;
        this.totalPages = data.lastPage;
      } catch (err) {
        return err;
      }
    },

    onDelete() {
      this.$root.alert("Pernottamento cancellato correttamente.");
      this.getRooms();
    }
  }
};
</script>

<style lang="scss" scoped>
.table {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  .no-padding {
    padding-top: 0;
    padding-bottom: 0;
  }

  .azioni {
    width: 12%;
    max-width: 12%;
    flex-basis: 12%;
  }

  .identificativo {
    width: 16%;
    max-width: 16%;
    flex-basis: 16%;
  }
  .camera {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .data {
    width: 12%;
    max-width: 12%;
    flex-basis: 12%;
  }

  .date-variabili {
    width: 10%;
    max-width: 10%;
    flex-basis: 10%;
  }
  .paganti {
    width: 9%;
    max-width: 9%;
    flex-basis: 9%;
  }
  .esenti {
    width: 8%;
    max-width: 8%;
    flex-basis: 8%;
  }
  .intermediario {
    width: 14%;
    max-width: 14%;
    flex-basis: 14%;
  }
  .totale {
    width: 9%;
    max-width: 9%;
    flex-basis: 9%;
  }
}
</style>
